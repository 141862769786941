import React, { useEffect, useState } from "react";
import "./Daily.css";
import { useTranslation } from "react-i18next";
import SvgIcon from "../svg-icon/SvgIcon";
import If from "../util/If";
import {
  getNextKey,
  formatter,
  enumerateDaysBetweenDates,
  mergeDatesToPeriods,
} from "app/utils";
import DatePickerCalendar from "../date-picker/DatePickerCalendar";
import moment from "moment";
import { Button } from "react-bootstrap";
import {
  RepresentationDayProps,
  RepresentationDates,
  IRepresentationDay,
} from "infrastructure/interfaces/Daily.interface";
import RepresentationDayMeals from "./RepresentationDayMeals";

const RepresentationDay: React.FC<RepresentationDayProps> = (props) => {
  const { t, i18n } = useTranslation();

  const [isShowDatePicker, setShowDatePicker] = useState(false);
  const [representationDay, setRepresentationDay] = useState<
    IRepresentationDay[]
  >([]);
  const [disabledDates, setDisabledDates] = useState<RepresentationDates[]>([]);

  const [isBreakfast, setBreakfast] = useState(false);
  const [isLunch, setLunch] = useState(false);
  const [isDinner, setDinner] = useState(false);
  const [selectedDays, setSelectedDays] = useState<Date[]>();

  const allowMeals =
    props.isBreakfastAccess || props.isLunchAccess || props.isDinnerAccess;

  const mealText = (item: IRepresentationDay) => {
    const text: string[] = [];
    if (item.isBreakfast && props.isBreakfastAccess) {
      text.push(
        t("create_expense.daily_allowance.representation_day_breakfast")
      );
    }
    if (item.isLunch && props.isLunchAccess) {
      text.push(t("create_expense.daily_allowance.representation_day_lunch"));
    }
    if (item.isDinner && props.isDinnerAccess) {
      text.push(t("create_expense.daily_allowance.representation_day_dinner"));
    }
    if (text.length > 0) {
      return text.join(" + ");
    }
  };

  useEffect(() => {
    setLunch(false);
    setDinner(false);
    setBreakfast(false);
    setSelectedDays([]);
  }, [isShowDatePicker]);

  useEffect(() => {
    const newPersonalDay = props.dates
      .filter((item) => item.isPrivateDay)
      .map((item) => ({ to: item.date, from: item.date }));
    const newRepresentationDay = props.dates
      .filter((item) => item.isRepresentationExpenses)
      .map((item) => ({ to: item.date, from: item.date }));
    const newDisabledDates = [...newPersonalDay, ...newRepresentationDay];

    setDisabledDates(newDisabledDates);
  }, [props.dates]);

  useEffect(() => {
    const newRepresentationDay: IRepresentationDay[] = [];
    if (props.dates && props.dates.length > 0) {
      const period = mergeDatesToPeriods(
        props.dates
          .filter((item) => item.isRepresentationExpenses)
          .map((item) => item.date),
        props.dates
      );

      period.forEach((element) => {
        newRepresentationDay.push({
          id: getNextKey("RepresentationDay"),
          period: element.from !== element.to,
          startDay: element.from,
          endDay: element.to,
          ...element,
        });
      });
    }

    setRepresentationDay(newRepresentationDay);
  }, [props.dates]);

  const onChangeForm = (newDateArray: Date[], isDelete: boolean) => {
    props.onChange(
      newDateArray.map((item) => ({
        date: item,
        mark: +moment(item).format("YYYYMMDD"),
        isBreakfast: isBreakfast,
        isDinner: isDinner,
        isLunch: isLunch,
      })),
      isDelete
    );
    !isDelete && toggleDatePicker();
  };

  const addDay = (value: any) => {
    let newDateArray = enumerateDaysBetweenDates(
      value.startDate,
      value.endDate,
      true
    );
    setSelectedDays(newDateArray);

    if (!allowMeals) {
      onChangeForm(newDateArray, false);
    }
  };

  const removeDay = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.currentTarget.dataset.id;
    const removeDay =
      target && representationDay.find((item) => item.id === +target);
    if (removeDay) {
      const newDay = enumerateDaysBetweenDates(
        removeDay.startDay,
        removeDay.endDay,
        true
      );

      onChangeForm(newDay, true);
    }
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!isShowDatePicker);
  };

  return (
    <>
      <label className="input-label">
        {t("create_expense.daily_allowance.representation_day")}
        {allowMeals &&
          ` (${mealText({
            ...representationDay[0],
            isBreakfast: true,
            isLunch: true,
            isDinner: true,
          })})`}
      </label>
      <div className="request-item-content">
        <DatePickerCalendar
          isShown={isShowDatePicker}
          isMultiChoice={true}
          children={
            allowMeals && (
              <RepresentationDayMeals
                setSelectedDays={setSelectedDays}
                isBreakfast={isBreakfast}
                isDinner={isDinner}
                isLunch={isLunch}
                setBreakfast={setBreakfast}
                setDinner={setDinner}
                setLunch={setLunch}
                onChangeForm={onChangeForm}
                selectedDays={selectedDays}
                toggleDatePicker={toggleDatePicker}
                {...props}
              />
            )
          }
          isCleansed={!allowMeals}
          onSelected={addDay}
          reset={isShowDatePicker}
          onClose={toggleDatePicker}
          disableBeforeDate={props.from}
          disableAfterDate={props.to}
          startDate={selectedDays && selectedDays[0]}
          endDate={selectedDays && selectedDays.at(-1)}
          disabledDates={disabledDates}
          month={props.from}
        />
        <div className="input-choose">
          {representationDay.map((item) => {
            return (
              <div className="input-choose-item" key={item.id}>
                {mealText(item)}{" "}
                {formatter(
                  "D MMMM",
                  i18n.language,
                  item.startDay,
                  item.endDay,
                  { withMonth: false, withYear: false }
                )}
                <If condition={!props.disabled}>
                  <div onClick={removeDay} data-id={item.id}>
                    <SvgIcon
                      className="icon icon-close"
                      href="#svg_icon_close"
                    />
                  </div>
                </If>
              </div>
            );
          })}
          <If condition={!props.disabled}>
            {props.isWidget ? (
              <Button
                variant={"outline-secondary"}
                className={"daily"}
                id="datepicker"
                onClick={toggleDatePicker}
              >
                + {t("create_expense.daily_allowance.add")}
              </Button>
            ) : (
              <div
                className="btn-expense btn_gray add-trip daily"
                id="datepicker"
                onClick={toggleDatePicker}
              >
                + {t("create_expense.daily_allowance.add")}
              </div>
            )}
          </If>
        </div>
      </div>
    </>
  );
};

export default RepresentationDay;
