import React from "react";
import { Notice } from "ui/Notice";
import { SignNoticeProps } from "./types";
import { useTranslation } from "react-i18next";

const SignNotice = (props: SignNoticeProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose, noticeType, text, style } = props;

  let notice;

  switch (noticeType) {
    case "notice_browser_plugin":
      notice = {
        header: "Внимание!",
        text: "Не обнаружено расширение КриптоПро ЭЦП Browser plug-in в браузере пользователя. Установите скрипт.",
        type: "alert",
      };
      break;
    case "success":
      notice = {
        header: "Документ успешно подписан",
        type: "success",
      };
      break;
    case "no_proper_certificate":
      notice = {
        header: "Нет подходящих сертификатов для подписания",
        type: "alert",
      };
      break;
    case "no_certificates":
      notice = {
        header: "Сертификаты не найдены",
        type: "alert",
      };
      break;
    case "failed_to_sign":
      notice = {
        header: t("signing.failed_to_sign"),
        type: "alert",
      };
      break;
    default:
      notice = {
        header: "Внимание!",
        text: text ? text : "Что-то пошло не так!",
        type: "alert",
      };
  }

  return (
    <>
      <Notice {...notice} isOpen={isOpen} onClose={onClose} style={style} />
    </>
  );
};

export default SignNotice;
