import { get, set } from "lodash";
import { postConfigList } from "./ApiService";

export enum ConfigTypes {
  ABILITY_USE_OR_NOT_EXPENSE = "AbilityUseOrNotExpense",
  ADVANCE_REPORT_AMOUNT_FULL_CALCULATION = "AdvanceReportAmountFullCalculation",
  EXPENSE_APPLICATION_AMOUNT_FULL_CALCULATION = "ExpenseApplicationAmountFullCalculation",
  DISPLAY_BUTTON_PRINT_DOCS_AFTER_APPROVE = "DisplayButtonPrintDocsAfterApprove",
  HIDE_VBS_ELEMENT_AND_ORDER_NUMBER = "hideVbsElementAndOrderNumber",
  HIDE_COST_CENTERS_BLOCK = "hideCostCentersBlock",
  DEPEND_APPROVED_AMOUNT_COMMENT_FROM_APPROVED_AMOUNT = "dependApprovedAmountCommentFromApprovedAmount",
  COST_CENTER_PERCENTAGE_SPLIT = "CostCenterPercentageSplit",
  PERDIEM_CALCULATION_TYPE = "PerDiemCalculationType",
  EDIT_COST_EXTERNAL_EXPENSE = "EditCostExternalExpense",
  ASSISTANCE_SOURCE = "AssistanceSource",
  IS_AVAILABLE_CURRENCY_FOR_APPROVED_AMOUNT = "IsAvailableCurrencyForApprovedAmount",
  EMPLOYEE_DEPUTIES_SOURCE = "EmployeeDeputiesSource",
  ADVANCE_REPORT_AMOUNT_CALCULATION = "AdvanceReportAmountCalculation",
  ADVANCE_CALCULATION_METHOD = "AdvanceCalculationMethod",
  LINK_TO_TIME_ENABLED = "LinkToTimeEnabled",
  ADVANCE_REPORT_APPLICATION_SIGNING_ENABLED = "AdvanceReportApplicationSigningEnabled",
  ADVANCE_REPORT_APPLICATION_TRAVEL_DOCUMENTS_SYNCHRONIZATION = "AdvanceReportApplicationTravelDocumentsSyncronization",
  ADVANCE_REPORT_APPLICATION_COMMON_DOCUMENTS_SYNCHRONIZATION = "AdvanceReportApplicationCommonDocumentsSynchronization",
  EXPENSE_APPLICATION_TRAVEL_DOCUMENTS_SYNCHRONIZATION = "ExpenseApplicationTravelDocumentsSynchronization",
  EXPENSE_APPLICATION_COMMON_DOCUMENTS_SYNCHRONIZATION = "ExpenseApplicationCommonDocumentsSynchronization",
  UPDATE_EXPENSE_APPLICATION_BY_ASSIGNEE_AS_CURRENT_APPROVER = "UpdateExpenseApplicationByAssigneeAsCurrentApprover",
  UPDATE_EXPENSE_APPLICATION_ON_APPROVAL_BY_COORDINATOR = "UpdateExpenseApplicationOnApprovalByCoordinator",
  UPDATE_EXPENSE_APPLICATION_BY_CURRENT_APPROVER = "UpdateExpenseApplicationByCurrentApprover",
  UPDATE_ADVANCE_REPORT_APPLICATION_BY_ASSIGNEE_AS_CURRENT_APPROVER = "UpdateAdvanceReportApplicationByAssigneeAsCurrentApprover",
  UPDATE_ADVANCE_REPORT_APPLICATION_ON_APPROVAL_BY_COORDINATOR = "UpdateAdvanceReportApplicationOnApprovalByCoordinator",
  UPDATE_ADVANCE_REPORT_APPLICATION_BY_CURRENT_APPROVER = "UpdateAdvanceReportApplicationByCurrentApprover",
  POWER_OF_ATTORNEY_ENABLED = "PowerOfAttorneyEnabled",
}

let configServiceStore = {
  setValue: (
    type: ConfigTypes,
    companyId: number,
    occupationIds: number[],
    value: any,
  ) => {
    set(configServiceStore.store, `${type}.company.c_${companyId}`, value);
    for (let index = 0; index < occupationIds.length; index++) {
      const occupationId = occupationIds[index];
      set(
        configServiceStore.store,
        `${type}.occupation.o_${occupationId}`,
        value,
      );
    }
  },
  getValueByCompanyId: (type: ConfigTypes, companyId: number) => {
    let value = get(
      configServiceStore.store,
      `${type}.company.c_${companyId}`,
      undefined,
    );
    return value;
  },
  getValueByOccupationId: (type: ConfigTypes, occupationId: number) => {
    let value = get(
      configServiceStore.store,
      `${type}.occupation.o_${occupationId}`,
      undefined,
    );
    return value;
  },
  store: {} as any,
};

const configService = {
  parameters1: {
    AbilityUseOrNotExpense: false,
    hideVbsElementAndOrderNumber: false,
    hideCostCentersBlock: false,
    dependApprovedAmountCommentFromApprovedAmount: false,
    costCenterPercentageSplit: true,
    perDiemCalculationType: 0,
    EditCostExternalExpense: false,
    AssistanceSource: 0,
    IsAvailableCurrencyForApprovedAmount: false,
    UpdateExpenseApplicationByAssigneeAsCurrentApprover: false,
    UpdateExpenseApplicationOnApprovalByCoordinator: false,
    UpdateExpenseApplicationByCurrentApprover: false,
    UpdateAdvanceReportApplicationByAssigneeAsCurrentApprover: false,
    UpdateAdvanceReportApplicationOnApprovalByCoordinator: false,
    UpdateAdvanceReportApplicationByCurrentApprover: false,
    PowerOfAttorneyEnabled: false,
  },
  init: async () => {
    let configResult = await postConfigList();

    if (configResult.data.map) {
      for (let index = 0; index < configResult.data.length; index++) {
        const configCodes = configResult.data[index];
        let { companyId, occupationIds } = configCodes;
        configServiceStore.setValue(
          ConfigTypes.ABILITY_USE_OR_NOT_EXPENSE,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "AbilityUseOrNotExpense",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.ADVANCE_REPORT_AMOUNT_FULL_CALCULATION,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "AdvanceReportAmountFullCalculation",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.EXPENSE_APPLICATION_AMOUNT_FULL_CALCULATION,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "ExpenseApplicationAmountFullCalculation",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.DISPLAY_BUTTON_PRINT_DOCS_AFTER_APPROVE,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "DisplayButtonPrintDocsAfterApprove",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.HIDE_VBS_ELEMENT_AND_ORDER_NUMBER,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "hideVbsElementAndOrderNumber",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.HIDE_COST_CENTERS_BLOCK,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "hideCostCentersBlock",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.DEPEND_APPROVED_AMOUNT_COMMENT_FROM_APPROVED_AMOUNT,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "dependApprovedAmountCommentFromApprovedAmount",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.COST_CENTER_PERCENTAGE_SPLIT,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "CostCenterPercentageSplit",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.PERDIEM_CALCULATION_TYPE,
          companyId,
          occupationIds,
          +get(configCodes.configValues, "PerDiemCalculationType", "0"),
        );

        configServiceStore.setValue(
          ConfigTypes.EDIT_COST_EXTERNAL_EXPENSE,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "EditCostExternalExpense",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.ASSISTANCE_SOURCE,
          companyId,
          occupationIds,
          +get(configCodes.configValues, "AssistanceSource", "0"),
        );

        configServiceStore.setValue(
          ConfigTypes.IS_AVAILABLE_CURRENCY_FOR_APPROVED_AMOUNT,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "IsAvailableCurrencyForApprovedAmount",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE,
          companyId,
          occupationIds,
          +get(configCodes.configValues, "EmployeeDeputiesSource", "0"),
        );

        configServiceStore.setValue(
          ConfigTypes.ADVANCE_REPORT_AMOUNT_CALCULATION,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "AdvanceReportAmountCalculation",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.ADVANCE_CALCULATION_METHOD,
          companyId,
          occupationIds,
          +get(configCodes.configValues, "AdvanceCalculationMethod", "0"),
        );

        configServiceStore.setValue(
          ConfigTypes.LINK_TO_TIME_ENABLED,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "LinkToTimeEnabled",
            "false",
          ).toLowerCase() == "true",
        );

        configServiceStore.setValue(
          ConfigTypes.ADVANCE_REPORT_APPLICATION_SIGNING_ENABLED,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "AdvanceReportApplicationSigningEnabled",
            "false",
          ).toLowerCase() === "true",
        );

        configServiceStore.setValue(
          ConfigTypes.ADVANCE_REPORT_APPLICATION_TRAVEL_DOCUMENTS_SYNCHRONIZATION,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "AdvanceReportApplicationTravelDocumentsSyncronization",
            "false",
          ).toLowerCase() === "true",
        );

        configServiceStore.setValue(
          ConfigTypes.ADVANCE_REPORT_APPLICATION_COMMON_DOCUMENTS_SYNCHRONIZATION,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "AdvanceReportApplicationCommonDocumentsSynchronization",
            "false",
          ).toLowerCase() === "true",
        );

        configServiceStore.setValue(
          ConfigTypes.EXPENSE_APPLICATION_TRAVEL_DOCUMENTS_SYNCHRONIZATION,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "ExpenseApplicationTravelDocumentsSynchronization",
            "false",
          ).toLowerCase() === "true",
        );

        configServiceStore.setValue(
          ConfigTypes.EXPENSE_APPLICATION_COMMON_DOCUMENTS_SYNCHRONIZATION,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "ExpenseApplicationCommonDocumentsSynchronization",
            "false",
          ).toLowerCase() === "true",
        );

        configServiceStore.setValue(
          ConfigTypes.UPDATE_EXPENSE_APPLICATION_BY_ASSIGNEE_AS_CURRENT_APPROVER,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "UpdateExpenseApplicationByAssigneeAsCurrentApprover",
            "false",
          ).toLowerCase() === "true",
        );

        configServiceStore.setValue(
          ConfigTypes.UPDATE_EXPENSE_APPLICATION_ON_APPROVAL_BY_COORDINATOR,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "UpdateExpenseApplicationOnApprovalByCoordinator",
            "false",
          ).toLowerCase() === "true",
        );
        configServiceStore.setValue(
          ConfigTypes.UPDATE_EXPENSE_APPLICATION_BY_CURRENT_APPROVER,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "UpdateExpenseApplicationByCurrentApprover",
            "false",
          ).toLowerCase() === "true",
        );
        configServiceStore.setValue(
          ConfigTypes.UPDATE_ADVANCE_REPORT_APPLICATION_BY_ASSIGNEE_AS_CURRENT_APPROVER,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "UpdateAdvanceReportApplicationByAssigneeAsCurrentApprover",
            "false",
          ).toLowerCase() === "true",
        );
        configServiceStore.setValue(
          ConfigTypes.UPDATE_ADVANCE_REPORT_APPLICATION_ON_APPROVAL_BY_COORDINATOR,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "UpdateAdvanceReportApplicationOnApprovalByCoordinator",
            "false",
          ).toLowerCase() === "true",
        );
        configServiceStore.setValue(
          ConfigTypes.UPDATE_ADVANCE_REPORT_APPLICATION_BY_CURRENT_APPROVER,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "UpdateAdvanceReportApplicationByCurrentApprover",
            "false",
          ).toLowerCase() === "true",
        );

        configServiceStore.setValue(
          ConfigTypes.POWER_OF_ATTORNEY_ENABLED,
          companyId,
          occupationIds,
          get(
            configCodes.configValues,
            "PowerOfAttorneyEnabled",
            "false",
          ).toLowerCase() === "true",
        );
      }
    }
  },

  getConfigToCompany: (type: ConfigTypes, companyId: number) => {
    return configServiceStore.getValueByCompanyId(type, companyId);
  },
  getConfigToOccupation: (type: ConfigTypes, occupationId: number) => {
    return configServiceStore.getValueByOccupationId(type, occupationId);
  },
};

export default configService;
