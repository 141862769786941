import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  CSSProperties,
} from "react";
import { useTranslation } from "react-i18next";
import { nameFormatter, NameFormats } from "app/utils";
import SvgIconS from "../svg-icon/SvgIcon";
import { SvgIcon } from "ui/SvgIcon";
import Slidebar from "../slidebar/Slidebar";
import { getEmployeeByParams } from "../../../services/ApiService";
import { useSelector } from "react-redux";
import { getCommonUserDetail } from "store/selectors";

interface EmployeeAutocompleteProps {
  defaultText?: string;
  placeholder?: string;
  onChoose?: (option: EmployeeAutocompleteOptionType) => void;
  readOnly?: boolean;
  ref?: any;
  style?: CSSProperties;
  tabIndex?: -1 | 0;
  dontLoop?: boolean;
  companyId?: number; // добавляет к ФИО еще должность в компании, чей id передан
  listOccupation?: boolean; // добавление компаний в список выбора сотрудников
  occupationId?: number;
}

export interface EmployeeAutocompleteOptionType {
  value: number;
  text: string;
  occupations?: any;
}

const EmployeeAutocomplete: React.FC<EmployeeAutocompleteProps> =
  React.forwardRef((props: EmployeeAutocompleteProps, ref: any) => {
    let { t, i18n } = useTranslation();

    let userDetail = useSelector(getCommonUserDetail);
    const userOccupations = userDetail.occupations.map((el) => el.company.id);

    let [isOpen, setOpen] = useState(false);
    let [isLoading, setLoading] = useState(false);
    let [inputValue, setInputValue] = useState(
      props.defaultText ? props.defaultText : ""
    );
    let [employees, setEmployees] = useState(
      [] as EmployeeAutocompleteOptionType[]
    );
    let [employeesWithOccupations, setEmployeesWithOccupations] = useState(
      [] as any
    );
    let [error, setError] = useState(false);
    let [employeeTimer, setEmployeeTimer] = useState({} as any);
    let [isSelected, setSelected] = useState(false);

    let inputRef = useRef({} as HTMLInputElement);

    useEffect(() => {
      setInputValue(props.defaultText ? props.defaultText : "");
    }, [props.defaultText]);

    useEffect(() => {
      if (employees.find((employee) => employee.text === inputValue)) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }, [inputValue, employees]);

    const onBlur = () => {
      if (!isSelected) {
        // props.companyId приходит только из служебных записок, в остальных случаях onBlur сбрасывает значение поля
        setInputValue(props.companyId && inputValue ? inputValue : "");
      }
    };

    const optionClickHandler = (option: EmployeeAutocompleteOptionType) => {
      if (props.companyId) {
        for (let i = 0; i < option.occupations.length; i++) {
          if (option.occupations[i].company.id === props.companyId) {
            setInputValue(
              `${option.text}, ${option.occupations[i].position ? option.occupations[i].position[i18n.language as "ru"] : ""}`
            );
          }
        }
      } else {
        setInputValue(option.text);
      }
      setOpen(false);
      if (props.onChoose) props.onChoose(option);
    };

    const changeInput = () => {
      props.onChoose && props.onChoose({} as EmployeeAutocompleteOptionType);
      setInputValue(inputRef.current.value);
      if (inputRef.current.value.length > 2) {
        clearTimeout(employeeTimer);
        let name = inputRef.current.value;
        (() => {
          setEmployeeTimer(
            setTimeout(() => {
              getEmployee(name);
            }, 800)
          );
        })();
      } else {
        setEmployees([] as EmployeeAutocompleteOptionType[]);
        setOpen(false);
        setLoading(false);
      }
    };

    const missClickHandler = () => {
      if (isOpen) {
        setOpen(false);
        setInputValue(props.defaultText ? props.defaultText : "");
      }
    };

    const getEmployee = async (nameValue: string) => {
      setError(false);
      setLoading(true);
      setEmployees([] as EmployeeAutocompleteOptionType[]);
      setOpen(true);
      let params = {};
      if (props.occupationId) {
        params = { OccupationCompanyId: props.occupationId };
      }
      try {
        let result = await getEmployeeByParams({
          params: { ...params, Name: nameValue, Includes: "Position" },
        });
        if (result.config.params.Name == inputRef.current.value) {
          setEmployeesWithOccupations(
            result.data.data.map((item: any) => {
              return {
                value: item.id,
                text: nameFormatter(item.name, NameFormats.FULL, i18n.language),
                occupations: item.occupations,
              };
            })
          );
          if (props.listOccupation) {
            setEmployees(getEmployeesWithOccupations(result.data.data));
          } else {
            setEmployees(
              result.data.data.map((item: any) => {
                return {
                  value: item.id,
                  text: nameFormatter(
                    item.name,
                    NameFormats.FULL,
                    i18n.language
                  ),
                  occupations: item.occupations,
                };
              })
            );
          }
        }
      } catch (e) {
        setError(true);
      }

      setLoading(false);
    };

    const getEmployeesWithOccupations = (data: any) => {
      const newArray: EmployeeAutocompleteOptionType[] = [];
      const callback = (item: any) => {
        item.occupations.forEach((el: any) => {
          if (userOccupations.includes(el.company.id)) {
            newArray.push({
              value: item.id,
              text: nameFormatter(item.name, NameFormats.FULL, i18n.language),
              occupations: el,
            });
          }
        });
      };
      data.forEach(callback);
      return newArray;
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        setInputValue("");
      },
      getOccupations: (emolyeeId: number) => {
        return (
          employeesWithOccupations.find(
            (item: any) => item.value === emolyeeId
          ) || []
        );
      },
      value: inputRef.current.value,
    }));

    return (
      <div className={"select " + (isOpen ? "active" : "")} style={props.style}>
        {isOpen ? (
          <div className="miss-click-handler" onClick={missClickHandler} />
        ) : (
          ""
        )}
        <input
          className="input"
          type="text"
          ref={inputRef}
          value={inputValue}
          onBlur={onBlur}
          onChange={changeInput}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          tabIndex={props.tabIndex}
          style={{ borderColor: props.companyId ? "#D9E2E7" : "#98A2A8" }}
        />
        {props.dontLoop ? (
          <div
            className="icon icon-search"
            style={{ fill: "none", right: "8px" }}
          >
            <SvgIcon id={"vectorDown"} />
          </div>
        ) : (
          <SvgIconS href={"#svg_icon_search"} className={"icon icon-search"} />
        )}
        <Slidebar
          className="select-content"
          style={{ zIndex: 20, maxHeight: "260px" }}
        >
          {employees.length > 0
            ? employees.map((option, index) => {
                return (
                  <div
                    className="select-content-item"
                    key={index}
                    onClick={() => optionClickHandler(option)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                    }}
                  >
                    <div style={{ justifyContent: "flex-start" }}>
                      {option.text}
                    </div>
                    {props.listOccupation && (
                      <div
                        style={{
                          justifyContent: "flex-start",
                          color: "#A2AFB5",
                          marginTop: "5px",
                        }}
                      >
                        {option.occupations.company.name.ru}
                      </div>
                    )}
                  </div>
                );
              })
            : null}
          {!error && !isLoading && employees.length === 0 ? (
            <div className="select-content-item">
              {t("create_expense.no_employee_found")}{" "}
            </div>
          ) : null}
          {error ? (
            <div className="select-content-item">
              {t("create_expense.search_error")}{" "}
            </div>
          ) : null}
          {isLoading ? (
            <div className="select-content-item">
              <div
                className="lds-ellipsis"
                style={{ marginLeft: "calc(50% - 30px)" }}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : null}
        </Slidebar>
      </div>
    );
  });

export default EmployeeAutocomplete;
