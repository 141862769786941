/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Daily.css";
import { useTranslation } from "react-i18next";
import { Checkbox } from "ui/Checkbox";
import Select, { SelectOptionType } from "../select/Select";
import { useDispatch, useSelector } from "react-redux";
import { loaderLock, loaderUnlock } from "store/common/actions";
import Config, { ConfigTypes } from "services/ConfigService";
import If from "../util/If";
import { get, uniqBy } from "lodash";
import {
  formatter,
  enumerateDaysBetweenDates,
  returnDateWithoutUTC,
  roundNumberToAccuracy,
  getCurrencieCode,
} from "app/utils";
import moment from "moment";
import { OCCUPATION_ROLE_TYPE } from "infrastructure/enum/user-role.enum";
import { IOccupations } from "infrastructure/interfaces";
import {
  getAdvanceReportApplication,
  getApplicationForExpenseDetail,
  getCommonPaymentForms,
  getCommonUserDetail,
} from "../../../store/selectors";
import {
  getCurrencies,
  getPerDiemCalculationsByObjectDetails,
  getPerDiemCompanyById,
  postPerDiemBudgeting,
} from "../../../services/ApiService";
import { EXPENSE_PROPETIES } from "../../../infrastructure/enum/object-properties.enum";
import { Button, Form } from "react-bootstrap";
import PersonalDay from "./PersonalDay";
import RepresentationDay from "./RepresentationDay";
import NutritionDay from "./NutritionDay";
import СalculationRow from "./CalculationRow";

interface DailyProps {
  isShow: boolean;
  editMode: boolean;
  documentId: number;
  expenseId: number;
  isAdvance?: boolean;
  date?: string;
  isExternal?: boolean;
  calculationDetails: string[];
  permissions: string[];
  onChangeResult: (
    value: {
      day: Date;
      amountCurrencyId: number;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      valuesParamByDay: {
        parameterId: number;
        parameterValue: string;
      }[];
    }[]
  ) => void;
  onChangeDailyStep: (step: number) => void;
  isWidget?: boolean;
}

const Daily: React.FC<DailyProps> = (props) => {
  let { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const expenseApplication = useSelector(getApplicationForExpenseDetail);
  const advanceReport = useSelector(getAdvanceReportApplication);
  const userDetail = useSelector(getCommonUserDetail);
  const paymentForms = useSelector(getCommonPaymentForms);

  const companyId = props.isAdvance
    ? advanceReport.company.id
    : expenseApplication
      ? expenseApplication.company.id
      : 0;
  const perDiemCalculationType = Config.getConfigToCompany(
    ConfigTypes.PERDIEM_CALCULATION_TYPE,
    companyId
  );

  let [perDiemParametersAccess, setPerDiemParametersAccess] = useState({
    isPrivateDay: false,
    isRepresentationExpenses: false,
    isFoodPresent: false,
    numberOfMeal: false,
    isBreakfast: false,
    isLunch: false,
    isDinner: false,
  });
  let [allDate, setAllDate] = useState(
    [] as {
      date: Date;
      mark: number;
      isPrivateDay: boolean;
      isRepresentationExpenses: boolean;
      numberOfMeal: number;
      isLunch: boolean;
      isDinner: boolean;
      isBreakfast: boolean;
      isFoodPresent: boolean;
    }[]
  );
  let [allDateResult, setAllDateResult] = useState(
    [] as {
      day: Date;
      amountCurrencyId: number;
      currencyCode: string;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      valuesParamByDay: {
        parameterId: number;
        parameterValue: string;
      }[];
    }[]
  );
  let [isFoodPresent, setIsFoodPresent] = useState(false);
  let [documentStartDate, setDocumentStartDate] = useState(new Date());
  let [documentEndDate, setDocumentEndDate] = useState(new Date());
  let [calculationResult, setCalculationResult] = useState(0);
  let [calculationStep, setCalculationStep] = useState(0);
  let [paymentForm, setPaymentForm] = useState({
    text: "---",
    value: 0,
  } as SelectOptionType);
  let [calculationRowShow, setСalculationRowShow] = useState(false);
  let [needChange, setNeedChange] = useState(new Date());

  useEffect(() => {
    if (paymentForms.length === 1) {
      setPaymentForm({
        value: paymentForms[0].id,
        text: paymentForms[0].name[i18n.language as "en" | "ru"],
      });
    }
  }, [paymentForms]);

  useEffect(() => {
    if (!props.isShow) {
      return;
    }

    if (props.editMode) {
      getCalculationParams();
      if (advanceReport && advanceReport.expenses) {
        let expense = advanceReport.expenses.find(
          (item) => item.id === props.expenseId
        );
        if (expense) {
          setPaymentForm({
            value: expense.paymentFormId.id,
            text: expense.paymentFormId.name[i18n.language as "en" | "ru"],
          });
        }
      } else if (expenseApplication && expenseApplication.expenses) {
        let expense: any = expenseApplication.expenses.find(
          (item) => item.id === props.expenseId
        );
        if (expense) {
          setPaymentForm({
            value: expense.paymentForm.id,
            text: expense.paymentForm.name[i18n.language as "en" | "ru"],
          });
        }
      }
    }

    setCalculationStep(1);

    getPerDiemParameters();

    if (props.isAdvance) {
      setDocumentStartDate(moment(advanceReport.startOn).toDate());
      setDocumentEndDate(moment(advanceReport.endOn).toDate());
    } else {
      setDocumentStartDate(
        expenseApplication
          ? moment(expenseApplication.startOn).toDate()
          : new Date()
      );
      setDocumentEndDate(
        expenseApplication
          ? moment(expenseApplication.endOn).toDate()
          : new Date()
      );
    }

    if (paymentForms.length === 1) {
      setPaymentForm({
        value: paymentForms[0].id,
        text: paymentForms[0].name[i18n.language as "en" | "ru"],
      });
    }
  }, [props.isShow, props.editMode]);

  useEffect(() => {
    props.onChangeDailyStep(calculationStep);
  }, [calculationStep]);

  useEffect(() => {
    let newAllDate = enumerateDaysBetweenDates(
      documentStartDate,
      documentEndDate,
      true
    ).map((date) => {
      return {
        date: date,
        mark: +moment(date).format("YYYYMMDD"),
        isPrivateDay: false,
        isRepresentationExpenses: false,
        isFoodPresent: false,
        numberOfMeal: 0,
        isBreakfast: false,
        isLunch: false,
        isDinner: false,
      };
    });

    let applicationTravelDates = [];
    if (props.isAdvance) {
      applicationTravelDates = advanceReport.applicationTravelDates;
    } else {
      applicationTravelDates = expenseApplication
        ? expenseApplication.expenseApplicationTravelDates
        : [];
    }
    let travelDatesArray = [] as number[];
    if (applicationTravelDates) {
      applicationTravelDates.forEach((item) =>
        travelDatesArray.push(
          ...enumerateDaysBetweenDates(item.startDate, item.endDate, true).map(
            (item) => +moment(item).format("YYYYMMDD")
          )
        )
      );
      uniqBy(travelDatesArray, (item) => item);
    }

    newAllDate.forEach((item) => {
      if (travelDatesArray.includes(item.mark)) {
        item.isPrivateDay = true;
      }
    });

    setAllDate(newAllDate);
  }, [documentStartDate, documentEndDate]);

  useEffect(() => {
    props.onChangeResult(allDateResult);
    let paymentFormFlag = true;
    allDateResult.forEach((item) => {
      if (
        item.amountRub !== 0 &&
        paymentFormFlag &&
        item.paymentForm.value === 0
      ) {
        paymentFormFlag = false;
      }
    });
    allDateResult.length > 0 && paymentFormFlag && setCalculationStep(4);
  }, [allDateResult]);

  const paymentFormOptions: SelectOptionType[] = paymentForms.map((form) => ({
    value: form.id,
    text: form.name[i18n.language as "en" | "ru"],
  }));

  const getPerDiemParameters = async () => {
    dispatch(loaderLock());

    let newPerDiemParametersAccess = {
      isPrivateDay: false,
      isRepresentationExpenses: false,
      isFoodPresent: false,
      numberOfMeal: false,
      isBreakfast: false,
      isLunch: false,
      isDinner: false,
    };

    let companyId = props.isAdvance
      ? advanceReport.company.id
      : expenseApplication
        ? expenseApplication.company.id
        : 0;

    let result = await getPerDiemCompanyById(companyId);

    if (result.headers.success && result.data) {
      if (
        result.data.find((item: any) => item.code === "IsPrivateDay") &&
        perDiemCalculationType !== 0
      ) {
        newPerDiemParametersAccess.isPrivateDay = true;
      }

      if (
        result.data.find(
          (item: any) => item.code === "IsRepresentationExpenses"
        ) &&
        perDiemCalculationType === 2
      ) {
        newPerDiemParametersAccess.isRepresentationExpenses = true;
      }

      if (
        result.data.find((item: any) => item.code === "IsFoodPresent") &&
        perDiemCalculationType === 2
      ) {
        newPerDiemParametersAccess.isFoodPresent = true;
      }

      if (
        result.data.find((item: any) => item.code === "NumberOfMeal") &&
        perDiemCalculationType === 2
      ) {
        newPerDiemParametersAccess.numberOfMeal = true;
      }

      if (
        result.data.find((item: any) => item.code === "IsBreakfast") &&
        perDiemCalculationType === 2
      ) {
        newPerDiemParametersAccess.isBreakfast = true;
      }

      if (
        result.data.find((item: any) => item.code === "IsLunch") &&
        perDiemCalculationType === 2
      ) {
        newPerDiemParametersAccess.isLunch = true;
      }

      if (
        result.data.find((item: any) => item.code === "IsDinner") &&
        perDiemCalculationType === 2
      ) {
        newPerDiemParametersAccess.isDinner = true;
      }
    }

    setPerDiemParametersAccess(newPerDiemParametersAccess);

    dispatch(loaderUnlock());
  };

  const calculation = async () => {
    dispatch(loaderLock());

    let data = {
      calculationParameters: allDate.map((item) => {
        return {
          day: returnDateWithoutUTC(item.date),
          perDiemParameters: [
            {
              code: "IsPrivateDay",
              value: item.isPrivateDay,
            },
            {
              code: "IsBreakfast",
              value: item.isBreakfast,
            },
            {
              code: "IsLunch",
              value: item.isLunch,
            },
            {
              code: "IsDinner",
              value: item.isDinner,
            },
            {
              code: "IsRepresentationExpenses",
              value: item.isRepresentationExpenses,
            },
            {
              code: "IsFoodPresent",
              value: item.isFoodPresent,
            },
            {
              code: "NumberOfMeal",
              value: item.numberOfMeal,
            },
          ],
        };
      }),
    };

    let calculationResultApi = await postPerDiemBudgeting(
      props.documentId,
      props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
      data
    );
    let currenciesResult = await getCurrencies({ params: { PageSize: 100 } });
    let companyId = props.isAdvance
      ? advanceReport.company.id
      : expenseApplication
        ? expenseApplication.company.id
        : 0;
    let perDiemParametersResult = await getPerDiemCompanyById(companyId);

    if (calculationResultApi.headers.success && calculationResultApi.data) {
      let currencyMap = new Map<string, number>();
      let perDiemParametersMap = new Map<string, number>();

      currenciesResult.data.data.forEach((item: any) =>
        currencyMap.set(
          get(item, "alphaCode", "").toLocaleLowerCase(),
          get(item, "id", 0)
        )
      );
      perDiemParametersResult.data.forEach((item: any) =>
        perDiemParametersMap.set(get(item, "code", ""), get(item, "id", 0))
      );

      interface IstartDaylyResult {
        day: Date;
        amount: number;
        currencyCode: string;
        currencyRate: number;
        amountRub: number;
      }

      let startDaylyResult: IstartDaylyResult[] =
        calculationResultApi.data.result.map((item: any) => {
          return {
            day: get(item, "day", new Date()),
            amount: roundNumberToAccuracy(get(item, "amount", new Date()), 2),
            currencyCode: get(item, "currencyCode", new Date()),
            currencyRate: get(item, "currencyRate", new Date()),
            amountRub: roundNumberToAccuracy(
              roundNumberToAccuracy(get(item, "amount", new Date()), 2) *
                roundNumberToAccuracy(get(item, "currencyRate", new Date()), 8),
              2
            ),
          };
        });

      let finalDaylyResult = startDaylyResult.map((item) => {
        let currentDay = {
          isPrivateDay: false,
          isRepresentationExpenses: false,
          numberOfMeal: 0,
          isBreakfast: false,
          isLunch: false,
          isDinner: false,
        };
        for (let index = 0; index < allDate.length; index++) {
          if (+moment(item.day).format("YYYYMMDD") === allDate[index].mark) {
            currentDay = {
              isPrivateDay: allDate[index].isPrivateDay,
              isRepresentationExpenses: allDate[index].isRepresentationExpenses,
              numberOfMeal: allDate[index].numberOfMeal,
              isBreakfast: allDate[index].isBreakfast,
              isLunch: allDate[index].isLunch,
              isDinner: allDate[index].isDinner,
            };
          }
        }

        let currencyId = 0;
        if (currencyMap.get(item.currencyCode.toLocaleLowerCase())) {
          currencyId = currencyMap.get(
            item.currencyCode.toLocaleLowerCase()
          ) as number;
        }

        let newDay = {
          day: returnDateWithoutUTC(moment(item.day).toDate()),
          amountCurrencyId: currencyId,
          amountCurrency: item.amount,
          currencyCode: item.currencyCode,
          currencyRate: item.currencyRate,
          amountRub: item.amountRub,
          paymentForm:
            paymentFormOptions.length === 1
              ? paymentFormOptions[0]
              : paymentForm.value
                ? paymentForm
                : { text: "---", value: 0 },
          valuesParamByDay: [] as {
            parameterId: number;
            parameterValue: string;
          }[],
        };

        if (perDiemParametersAccess.isPrivateDay) {
          newDay.valuesParamByDay.push({
            parameterId: perDiemParametersMap.get("IsPrivateDay") as number,
            parameterValue: String(currentDay.isPrivateDay),
          });
        }

        if (perDiemParametersAccess.isRepresentationExpenses) {
          newDay.valuesParamByDay.push({
            parameterId: perDiemParametersMap.get(
              "IsRepresentationExpenses"
            ) as number,
            parameterValue: String(currentDay.isRepresentationExpenses),
          });
        }

        if (perDiemParametersAccess.numberOfMeal) {
          newDay.valuesParamByDay.push({
            parameterId: perDiemParametersMap.get("NumberOfMeal") as number,
            parameterValue: String(currentDay.numberOfMeal),
          });
        }

        if (perDiemParametersAccess.isBreakfast) {
          newDay.valuesParamByDay.push({
            parameterId: perDiemParametersMap.get("IsBreakfast") as number,
            parameterValue: String(currentDay.isBreakfast),
          });
        }

        if (perDiemParametersAccess.isLunch) {
          newDay.valuesParamByDay.push({
            parameterId: perDiemParametersMap.get("IsLunch") as number,
            parameterValue: String(currentDay.isLunch),
          });
        }

        if (perDiemParametersAccess.isDinner) {
          newDay.valuesParamByDay.push({
            parameterId: perDiemParametersMap.get("IsDinner") as number,
            parameterValue: String(currentDay.isDinner),
          });
        }

        return newDay;
      });

      let calcResult = 0;

      for (let index = 0; index < finalDaylyResult.length; index++) {
        const element = finalDaylyResult[index];
        calcResult += element.amountRub;
      }

      setCalculationStep(paymentFormOptions.length === 1 ? 4 : 3);
      setAllDateResult(finalDaylyResult);
      setCalculationResult(calcResult);
      if (paymentFormOptions.length === 1) {
        setPaymentForm(paymentFormOptions[0]);
      }
      setNeedChange(new Date());
    }

    dispatch(loaderUnlock());
  };

  const getCalculationParams = async () => {
    dispatch(loaderLock());

    let startOn = new Date();
    let endOn = new Date();
    if (props.isAdvance) {
      startOn = moment(advanceReport.startOn).toDate();
      endOn = moment(advanceReport.endOn).toDate();
    } else {
      startOn = expenseApplication
        ? moment(expenseApplication.startOn).toDate()
        : new Date();
      endOn = expenseApplication
        ? moment(expenseApplication.endOn).toDate()
        : new Date();
    }

    let calculationResult = await getPerDiemCalculationsByObjectDetails(
      props.documentId,
      props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication"
    );
    let currenciesResult = await getCurrencies({ params: { PageSize: 100 } });
    let companyId = props.isAdvance
      ? advanceReport.company.id
      : expenseApplication
        ? expenseApplication.company.id
        : 0;
    let perDiemParametersResult = await getPerDiemCompanyById(companyId);

    if (calculationResult.headers.success && calculationResult.data) {
      let currencyMap = new Map<string, number>();
      let perDiemParametersMap = new Map<string, number>();

      currenciesResult.data.data.forEach((item: any) =>
        currencyMap.set(
          get(item, "alphaCode", "").toLocaleLowerCase(),
          get(item, "id", 0)
        )
      );
      perDiemParametersResult.data.forEach((item: any) =>{
        perDiemParametersMap.set(get(item, "code", ""), get(item, "id", 0))
    });

      let calculation = calculationResult.data.value.calculations;

      let newAllDate = [] as {
        date: Date;
        mark: number;
        isPrivateDay: boolean;
        isRepresentationExpenses: boolean;
        numberOfMeal: number;
        isFoodPresent: boolean;
        isLunch: boolean;
        isDinner: boolean;
        isBreakfast: boolean;
      }[];

      let newAllDateResult = [] as {
        day: Date;
        amountCurrencyId: number;
        currencyCode: string;
        amountCurrency: number;
        currencyRate: number;
        amountRub: number;
        paymentForm: SelectOptionType;
        valuesParamByDay: {
          parameterId: number;
          parameterValue: string;
        }[];
      }[];

      enumerateDaysBetweenDates(startOn, endOn, true).forEach((date) => {
        newAllDate.push({
          date: date,
          mark: +moment(date).format("YYYYMMDD"),
          isPrivateDay: false,
          isRepresentationExpenses: false,
          isFoodPresent: false,
          isLunch: false,
          isDinner: false,
          isBreakfast: false,
          numberOfMeal: 0,
        });
      });

      calculation.forEach((item: any) => {
        let a = {
          date: moment(item.calculation.day).toDate(),
          mark: +moment(item.calculation.day).format("YYYYMMDD"),
          isPrivateDay: false,
          isRepresentationExpenses: false,
          isFoodPresent: false,
          numberOfMeal: 0,
          isLunch: false,
          isDinner: false,
          isBreakfast: false,
        };

        let b = {
          day: returnDateWithoutUTC(moment(item.calculation.day).toDate()),
          amountCurrencyId: currencyMap.get(
            item.calculation.currency.alphaCode.toLocaleLowerCase()
          ) as number,
          currencyCode: item.calculation.currency.alphaCode,
          amountCurrency: item.calculation.amountCurrency,
          currencyRate: item.calculation.rate,
          amountRub: roundNumberToAccuracy(
            item.calculation.amountCurrency * item.calculation.rate,
            8
          ),
          paymentForm: { text: "---", value: 0 },
          valuesParamByDay: [] as {
            parameterId: number;
            parameterValue: string;
          }[],
        };

        item.paramValues.forEach((value: any) => {
          b.valuesParamByDay.push({
            parameterId: perDiemParametersMap.get(value.code) as number,
            parameterValue: String(value.value),
          });
          if (value.code === "IsPrivateDay") {
            a.isPrivateDay = "true" === value.value.toLowerCase();
          }
          if (value.code === "IsRepresentationExpenses") {
            a.isRepresentationExpenses = "true" === value.value.toLowerCase();
          }
          if (value.code === "IsLunch") {
            a.isLunch = "true" === value.value.toLowerCase();
          }
          if (value.code === "IsDinner") {
            a.isDinner = "true" === value.value.toLowerCase();
          }
          if (value.code === "IsBreakfast") {
            a.isBreakfast = "true" === value.value.toLowerCase();
          }
          if (value.code === "NumberOfMeal") {
            if (+value.value !== 0) {
              a.isFoodPresent = true;
              setIsFoodPresent(true);
            }
            a.numberOfMeal = +value.value;
          }
        });

        for (let index = 0; index < newAllDate.length; index++) {
          if (newAllDate[index].mark === a.mark) {
            newAllDate[index] = a;
            newAllDateResult.push(b);
          }
        }
      });

      newAllDate = newAllDate.filter(
        (item) =>
          +moment(startOn).format("YYYYMMDD") <= item.mark &&
          item.mark <= +moment(endOn).format("YYYYMMDD")
      );
      newAllDateResult = newAllDateResult.filter(
        (item) =>
          +moment(startOn).format("YYYYMMDD") <=
            +moment(item.day).format("YYYYMMDD") &&
          +moment(item.day).format("YYYYMMDD") <=
            +moment(endOn).format("YYYYMMDD")
      );

      let newCalculationResult = 0;
      newAllDateResult.forEach((item) => {
        newCalculationResult += item.amountRub;
      });

      if (paymentForms.length === 1) {
        setPaymentForm({
          value: paymentForms[0].id,
          text: paymentForms[0].name[i18n.language as "en" | "ru"],
        });
      }

      setAllDate(newAllDate);
      setAllDateResult(newAllDateResult);
      setCalculationResult(newCalculationResult);
    }

    dispatch(loaderUnlock());
  };

  const onChangePersonalDay = (
    value: { date: Date; mark: number }[],
    isDelete: boolean
  ) => {
    let newAllDate = [...allDate];
    let markArray = value.map((item) => item.mark);
    if (isDelete) {
      newAllDate.forEach((item) => {
        if (markArray.includes(item.mark)) {
          item.isPrivateDay = false;
          item.isFoodPresent = false;
          item.isRepresentationExpenses = false;
          item.numberOfMeal = 0;
        }
      });
    } else {
      newAllDate.forEach((item) => {
        if (markArray.includes(item.mark)) {
          item.isPrivateDay = true;
          item.isFoodPresent = false;
          item.isRepresentationExpenses = false;
          item.numberOfMeal = 0;
        }
      });
    }

    setAllDate(newAllDate);

    if (calculationStep !== 1) {
      setCalculationStep(2);
    }
  };

  const onChangeRepresentationDay = (
    value: {
      date: Date;
      mark: number;
      isLunch?: boolean;
      isDinner?: boolean;
      isBreakfast?: boolean;
    }[],
    isDelete: boolean,
    submit?: boolean
  ) => {
    let newAllDate = [...allDate];
    let markArray = value.map((item) => item.mark);
    if (isDelete) {
      newAllDate.forEach((item) => {
        if (markArray.includes(item.mark)) {
          item.isRepresentationExpenses = false;
          item.isBreakfast = false;
          item.isLunch = false;
          item.isDinner = false;
        }
      });
    } else {
      newAllDate.forEach((item) => {
  
        if (markArray.includes(item.mark) && !item.isPrivateDay) {
          item.isFoodPresent = false;
          item.numberOfMeal = 0;
          item.isRepresentationExpenses = true;
          item.isBreakfast =
            value && value.find((el) => el.isBreakfast) ? true : false;
          item.isLunch = value && value.find((el) => el.isLunch) ? true : false;
          item.isDinner =
          value && value.find((el) => el.isDinner) ? true : false;
        }
      });
    }

    // const access = perDiemParametersAccess

    // if (!access.isBreakfast && !access.isDinner && !access.isLunch) {
    //   setAllDate(newAllDate);
    // } else if (submit) {
    //   setAllDate(newAllDate);
    // }
    setAllDate(newAllDate);
    

    if (calculationStep !== 1) {
      setCalculationStep(2);
    }
  };

  const onChangeCountNutritionDay = (date: Date) => {
    let newAllDate = [...allDate];
    let newMark = +moment(date).format("YYYYMMDD");

    for (let index = 0; index < newAllDate.length; index++) {
      if (newAllDate[index].mark === newMark) {
        newAllDate[index].isFoodPresent = true;
        newAllDate[index].numberOfMeal += 1;
        if (newAllDate[index].numberOfMeal === 4) {
          newAllDate[index].isFoodPresent = false;
          newAllDate[index].numberOfMeal = 0;
        }
        break;
      }
    }

    setAllDate(newAllDate);

    if (calculationStep !== 1) {
      setCalculationStep(2);
    }
  };

  const onChangeIsFoodPresent = () => {
    if (isFoodPresent) {
      let newAllDate = allDate.map((item) => {
        item.numberOfMeal = 0;
        item.isFoodPresent = false;
        return item;
      });

      setAllDate(newAllDate);
    }
    setIsFoodPresent(!isFoodPresent);

    if (calculationStep !== 1) {
      setCalculationStep(2);
    }
  };

  const onChangeСalculationRow = (
    dates: Date[],
    property: string,
    value: any
  ) => {
    let newDatesMark = dates.map((item) => +moment(item).format("YYYYMMDD"));

    let newAllDateResult = [...allDateResult];

    newAllDateResult.forEach((item) => {
      if (newDatesMark.includes(+moment(item.day).format("YYYYMMDD"))) {
        if (property === "paymentForm") {
          item.paymentForm = value;
        }
        if (property === "currency") {
          item.amountCurrencyId = value.id;
          item.currencyCode = value.alphaCode;
          item.currencyRate = value.rate;
          item.amountRub = roundNumberToAccuracy(
            item.amountCurrency * value.rate,
            8
          );
        }
        if (property === "amount") {
          item.amountCurrency = roundNumberToAccuracy(value, 2);
          item.amountRub = roundNumberToAccuracy(item.currencyRate * value, 8);
        }
        if (property === "rate") {
          item.currencyRate = value;
          item.amountRub = roundNumberToAccuracy(
            item.amountCurrency * value,
            5
          );
        }
        if (property === "all") {
          item.amountCurrencyId = value.amountCurrencyId;
          item.currencyCode = value.currencyCode;
          item.amountCurrency = roundNumberToAccuracy(value.amountCurrency, 2);
          item.currencyRate = value.currencyRate;
          item.amountRub = roundNumberToAccuracy(value.amountRub, 8);
          item.paymentForm = value.paymentForm;
        }
      }
    });

    let calcResult = 0;

    for (let index = 0; index < newAllDateResult.length; index++) {
      const element = newAllDateResult[index];
      calcResult += element.amountRub;
    }

    setCalculationResult(calcResult);

    setAllDateResult(newAllDateResult);
  };

  const paymentFormChoseHandler = (option: SelectOptionType) => {
    let newAllDateResult = [...allDateResult];

    newAllDateResult.forEach((item) => {
      item.paymentForm = option;
    });

    setAllDateResult(newAllDateResult);
    setPaymentForm(option);
  };

  const isBookerOccupation = (): boolean => {
    let occupations = userDetail.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  return (
    <div className={`input-wrap`} id="add-expenses">
      <div className="box-modal-form-block">
        <div className="title">
          {t("create_expense.daily_allowance.title", {
            startDate: formatter(
              "D MMMM",
              i18n.language,
              documentStartDate,
              documentStartDate,
              { withMonth: false, withYear: false }
            ),
            endDate: formatter(
              "D MMMM",
              i18n.language,
              documentEndDate,
              documentEndDate,
              { withMonth: false, withYear: false }
            ),
          })}
        </div>
        <If condition={perDiemParametersAccess.isPrivateDay}>
          <div className="input-item">
            <PersonalDay
              dates={allDate}
              onChange={onChangePersonalDay}
              from={documentStartDate}
              to={documentEndDate}
              disabled={
                !props.permissions.includes(
                  EXPENSE_PROPETIES.CALCULATION_PARAMETERS
                )
              }
              isWidget={props.isWidget}
            />
          </div>
        </If>
        <If condition={perDiemParametersAccess.isRepresentationExpenses}>
          <div className="input-item">
            <RepresentationDay
              dates={allDate}
              isBreakfastAccess={perDiemParametersAccess.isBreakfast}
              isLunchAccess={perDiemParametersAccess.isLunch}
              isDinnerAccess={perDiemParametersAccess.isDinner}
              onChange={onChangeRepresentationDay}
              from={documentStartDate}
              to={documentEndDate}
              disabled={
                !props.permissions.includes(
                  EXPENSE_PROPETIES.CALCULATION_PARAMETERS
                )
              }
              isWidget={props.isWidget}
            />
          </div>
        </If>
        <If condition={perDiemParametersAccess.isFoodPresent}>
          <div className={props.isWidget ? "" : "input-item"}>
            <Checkbox
              name={"numberOfMeal"}
              id={1}
              label={t("create_expense.daily_allowance.food_present")}
              checked={isFoodPresent}
              onClick={onChangeIsFoodPresent}
              disabled={
                !props.permissions.includes(
                  EXPENSE_PROPETIES.CALCULATION_PARAMETERS
                )
              }
            />
          </div>
        </If>
        <If
          condition={
            (!perDiemParametersAccess.isFoodPresent &&
              perDiemParametersAccess.numberOfMeal) ||
            (perDiemParametersAccess.isFoodPresent &&
              perDiemParametersAccess.numberOfMeal &&
              isFoodPresent)
          }
        >
          <div className="input-item">
            <NutritionDay
              nutritionDay={allDate}
              onChangeCount={onChangeCountNutritionDay}
              from={documentStartDate}
              to={documentEndDate}
              disabled={
                !props.permissions.includes(
                  EXPENSE_PROPETIES.CALCULATION_PARAMETERS
                )
              }
              isWidget={props.isWidget}
            />
          </div>
        </If>
        <div className="input-item">
          <If
            condition={
              props.permissions.includes(
                EXPENSE_PROPETIES.CALCULATIONS_DETAILS
              ) ||
              props.permissions.includes(
                EXPENSE_PROPETIES.CALCULATION_PARAMETERS
              )
            }
          >
            {props.isWidget ? (
              <Button
                variant={"outline-secondary"}
                className="daily"
                onClick={calculation}
              >
                {t("create_expense.daily_allowance.calculate")}
              </Button>
            ) : (
              <div
                className="btn-expense btn_gray add-trip daily"
                onClick={calculation}
              >
                {t("create_expense.daily_allowance.calculate")}
              </div>
            )}
          </If>
        </div>
        <div className="input-item-row input-item-row-wrap">
          {props.isWidget ? (
            <Form.Group
              className="input-item"
              style={{ width: "150px", minWidth: 0, maxWidth: "none" }}
            >
              <Form.Label className="input-label">
                {t("create_expense.daily_allowance.result", {
                  currency: getCurrencieCode("rub"),
                })}
              </Form.Label>
              <Form.Control
                type="text"
                value={roundNumberToAccuracy(calculationResult, 2)}
                style={{ marginBottom: "5px" }}
              />
            </Form.Group>
          ) : (
            <div
              className="input-item"
              style={{ width: "150px", minWidth: 0, maxWidth: "none" }}
            >
              <label className="input-label">
                {t("create_expense.daily_allowance.result", {
                  currency: getCurrencieCode("rub"),
                })}
              </label>
              <input
                className="input"
                type="text"
                value={roundNumberToAccuracy(calculationResult, 2)}
                style={{ marginBottom: "5px" }}
              />
            </div>
          )}
        </div>
        <div
          className="input-item"
          style={{ width: "170px", minWidth: 0, maxWidth: "none" }}
        >
          <label className="input-label">
            {t("create_expense.daily_allowance.payment_form")}
          </label>
          <Select
            options={paymentFormOptions}
            defaultValue={paymentForm}
            onChoose={paymentFormChoseHandler}
            disabled={
              !props.permissions.includes(
                EXPENSE_PROPETIES.CALCULATION_PARAMETERS
              )
            }
            style={{ lineHeight: "38px" }}
            isWidget={props.isWidget}
          />
        </div>
      </div>
      <If
        condition={props.calculationDetails.includes(
          EXPENSE_PROPETIES.CALCULATIONS_DETAILS
        )}
      >
        <span
          className={"daily-detail pointer"}
          onClick={() => {
            setСalculationRowShow(!calculationRowShow);
          }}
        >
          {t("create_expense.daily_allowance.calculate_detail")}
        </span>
      </If>
      <If condition={calculationRowShow}>
        <СalculationRow
          disabled={
            !props.permissions.includes(EXPENSE_PROPETIES.CALCULATIONS_DETAILS)
          }
          permissions={props.permissions}
          dates={allDate}
          initialAllDateResult={allDateResult}
          onChange={onChangeСalculationRow}
          from={documentStartDate}
          to={documentEndDate}
          needChange={needChange}
          companyId={
            props.isAdvance
              ? advanceReport.company.id
              : expenseApplication
                ? expenseApplication.company.id
                : 0
          }
        />
      </If>
    </div>
  );
};

export default Daily;
