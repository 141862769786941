import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "./AOExpenses.css";
import { IAO } from "../../../infrastructure/interfaces";
import { loaderLock, loaderUnlock } from "../../../store/common/actions";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { getCurrencieCode, toLocaleNumberFormatter } from "app/utils";
import TextCutter from "app/component/text-cutter/TextCutter";
import { useWidth } from "app/hooks";
import { ADVANCE_APPLICATION_PROPETIES } from "../../../infrastructure/enum/object-properties.enum";
import { getAdvanceReportApplication } from "../../../store/selectors";
import { deleteAdvanceReportAdditionalDocumentsById } from "../../../services/ApiService";
import { updateDetailedAO } from "../../../store/report/actions";
import { ReportActStatus } from "ui/ReportActStatus";

interface AODocumentProps {
  item: any;
  setActId: (a: number) => void;
  setOpenActModal: (a: boolean) => void;
  setMemoId: (a: number) => void;
  setOpenMemoModal: (a: boolean) => void;
}

const AODocument: React.FC<AODocumentProps> = ({
  item,
  setActId,
  setOpenActModal,
  setMemoId,
  setOpenMemoModal,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication,
  );

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  let nameRef = useRef(null as any);
  let width = useWidth(nameRef);

  const handleActEdit = async (id: number) => {
    setActId(id);
    setOpenActModal(true);
  };

  const handleMemoEdit = async (id: number) => {
    setMemoId(id);
    setOpenMemoModal(true);
  };

  const handleDocumentDelete = async (id: number) => {
    dispatch(loaderLock());
    let result = await deleteAdvanceReportAdditionalDocumentsById(id);

    if (result.headers.success) {
      dispatch(updateDetailedAO(advanceReportApplication.id));
    }
    dispatch(loaderUnlock());
  };

  const handleButtonsDropDown = () => {
    setIsOpenDropDown(!isOpenDropDown);
  };

  return (
    <>
      <div className="request-expenses-item" style={{ paddingLeft: "5px" }}>
        <div className="request-expenses-block info" ref={nameRef}>
          <div className="request-expenses-item-text" style={{ order: 1 }}>
            {item.documentType !== "ServiceNote"
              ? t("request_detail.application_expenses.actTitle", {
                  documentNumber: item.documentNumber,
                })
              : t("request_detail.application_expenses.memoTitle", {
                  documentNumber: item.documentNumber,
                })}
          </div>
          <div className="request-item-name" style={{ order: 2 }}>
            <TextCutter
              text={item.description}
              parentWidth={width}
              defaultLength={50}
              options={[
                { maxLength: 50, minWidth: 401, maxWidth: 900, symbolSize: 29 },
                { maxLength: 20, minWidth: 0, maxWidth: 400, symbolSize: 29 },
              ]}
            />
          </div>
          <div
            className="request-expenses-item-file"
            style={{ fontSize: "14px" }}
          >
            <div>
              <TextCutter
                text={
                  item.documentType !== "ServiceNote" ? item.meetingPlace : ""
                }
                parentWidth={width}
                defaultLength={75}
                options={[
                  {
                    maxLength: 75,
                    minWidth: 401,
                    maxWidth: 900,
                    symbolSize: 14,
                  },
                  { maxLength: 20, minWidth: 0, maxWidth: 400, symbolSize: 14 },
                ]}
              />{" "}
              {item.documentType !== "ServiceNote"
                ? moment(item.meetingDate).format("DD.MM.YYYY")
                : moment(item.serviceNote.serviceNoteDate).format("DD.MM.YYYY")}
            </div>
          </div>
        </div>
        <div className="flex-sm-row flex-sm-between">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginRight: "14px",
            }}
          >
            <div style={{ alignSelf: "end" }}>
              {item.documentType !== "ServiceNote"
                ? t("request_detail.application_expenses.totalAmountTitle")
                : t("request_detail.application_expenses.totalAmountMemo")}
            </div>
            <div
              style={{ alignSelf: "end", marginRight: "0", paddingTop: "10px" }}
              className="request-list-item-cost"
            >{`${toLocaleNumberFormatter(item.totalAmount)} ${getCurrencieCode(
              "RUB",
            )}`}</div>
            <ReportActStatus id={item.id} />
          </div>
          <div className="request-expenses-block controls">
            <SvgIcon
              className="icon icon-more pointer"
              href="#svg_icon_more"
              onClick={handleButtonsDropDown}
            />
            {isOpenDropDown &&
              (item.documentType !== "ServiceNote"
                ? advanceReportApplication.properties.includes(
                    ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES_UPDATE,
                  ) && (
                    <div
                      className="request-files-item-dropdown"
                      style={{ display: "block", top: "40px" }}
                    >
                      <a
                        className="pointer"
                        style={{ display: "flex", marginBottom: "25px" }}
                        onClick={() => {
                          handleActEdit(item.id);
                        }}
                      >
                        <SvgIcon
                          className="icon icon-edit"
                          href="#svg_icon_edit"
                        />
                        {t("request_detail.application_expenses.edit")}
                      </a>
                      <a
                        className="pointer"
                        style={{ display: "flex" }}
                        onClick={() => {
                          handleDocumentDelete(item.id);
                        }}
                      >
                        <SvgIcon
                          className="icon icon-remove"
                          href="#svg_icon_remove"
                        />
                        {t("request_detail.application_expenses.delete")}
                      </a>
                    </div>
                  )
                : advanceReportApplication.properties.includes(
                    ADVANCE_APPLICATION_PROPETIES.SERVICE_NOTE_UPDATE,
                  ) && (
                    <div
                      className="request-files-item-dropdown"
                      style={{ display: "block", top: "40px" }}
                    >
                      <a
                        className="pointer"
                        style={{ display: "flex", marginBottom: "25px" }}
                        onClick={() => {
                          handleMemoEdit(item.id);
                        }}
                      >
                        <SvgIcon
                          className="icon icon-edit"
                          href="#svg_icon_edit"
                        />
                        {t("request_detail.application_expenses.edit")}
                      </a>
                      <a
                        className="pointer"
                        style={{ display: "flex" }}
                        onClick={() => {
                          handleDocumentDelete(item.id);
                        }}
                      >
                        <SvgIcon
                          className="icon icon-remove"
                          href="#svg_icon_remove"
                        />
                        {t("request_detail.application_expenses.delete")}
                      </a>
                    </div>
                  ))}
            {item.documentType !== "ServiceNote" ? (
              <div className="controls-dropdown">
                {advanceReportApplication.properties.includes(
                  ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES_UPDATE,
                ) && (
                  <>
                    <a
                      className="pointer"
                      onClick={() => {
                        handleActEdit(item.id);
                      }}
                    >
                      <SvgIcon
                        className="icon icon-edit"
                        href="#svg_icon_edit"
                      />
                    </a>
                    <a
                      className="delete pointer"
                      onClick={() => {
                        handleDocumentDelete(item.id);
                      }}
                    >
                      <SvgIcon
                        className="icon icon-remove"
                        href="#svg_icon_remove"
                      />
                    </a>
                  </>
                )}
              </div>
            ) : (
              <div className="controls-dropdown">
                {advanceReportApplication.properties.includes(
                  ADVANCE_APPLICATION_PROPETIES.SERVICE_NOTE_UPDATE,
                ) && (
                  <>
                    <a
                      className="pointer"
                      onClick={() => {
                        handleMemoEdit(item.id);
                      }}
                    >
                      <SvgIcon
                        className="icon icon-edit"
                        href="#svg_icon_edit"
                      />
                    </a>
                    <a
                      className="delete pointer"
                      onClick={() => {
                        handleDocumentDelete(item.id);
                      }}
                    >
                      <SvgIcon
                        className="icon icon-remove"
                        href="#svg_icon_remove"
                      />
                    </a>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AODocument;
