import Oidc, { Log, User, UserManager } from "oidc-client";
import analitiks from "services/AnaliticsService";
import { LANDING_URL } from "../infrastructure/constants/Locations";

const stsAuthority = process.env.REACT_APP_AUTH_PASSPORT_URL;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const clientRoot = window.location.origin + "/";
const clientScope = process.env.REACT_APP_AUTH_SCOPE;

export class AuthService {
  public userManager: UserManager;
  private timeout: any = null;

  constructor() {
    const settings = {
      authority: stsAuthority,
      client_id: clientId,
      redirect_uri: `${clientRoot}`,
      silent_redirect_uri: `${clientRoot}silent-renew.html`,
      // tslint:disable-next-line:object-literal-sort-keys
      post_logout_redirect_uri: `${clientRoot}`,
      response_type: "id_token token",
      scope: clientScope,
    };
    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public async getUser(): Promise<User | null> {
    const user = await this.userManager.getUser();
    this.onEndToken(user);
    return user;
  }

  private onEndToken(user: User | null) {
    this.timeout && clearTimeout(this.timeout);
    const now = Math.round(Date.now() / 1000);
    let time = user ? user.expires_at - now : 0;
    if (time) {
      this.timeout = setTimeout(() => {
        window.location.replace(LANDING_URL);
      }, time * 1000);
    }
  }

  public login(): Promise<void> {
    localStorage.setItem("redirectLocation", window.location.href);
    return this.userManager.signinRedirect();
  }

  public async renewToken(): Promise<User> {
    const user = await this.userManager.signinSilent();
    this.onEndToken(user);
    return user;
  }

  public async logout(): Promise<void> {
    const user = await this.userManager.getUser();
    if (user) {
      await this.userManager.signoutRedirect({
        id_token_hint: `${user.id_token}`,
        scope: user.scope,
      });
    }
  }

  public parse_hash_string(hashString: string) {
    const hash = hashString.substr(1);

    return hash.split("&").reduce((result: any, item: any) => {
      const parts = item.split("=");
      result[parts[0]] = parts[1];
      return result;
    }, {});
  }

  public findRedirectLocation() {
    const state = this.parse_hash_string(window.location.hash)["redirect"];
    const directLink = state ? "/#" + window.atob(state) : null;
    if (directLink) {
      localStorage.setItem("redirectLocation", directLink);
      this.setCustomState();
    }
    const redirectLocation = directLink
      ? directLink
      : localStorage.getItem("redirectLocation");
    return redirectLocation
      ? redirectLocation
      : new URL(window.location.href).origin;
  }

  public setCustomState() {
    const state = this.parse_hash_string(window.location.hash)["state"];
    localStorage.setItem(
      "oidc." + state,
      JSON.stringify({
        id: state,
        created: new Date().getTime(),
        redirect_uri: clientRoot,
        authority: stsAuthority,
        client_id: clientId,
      }),
    );
  }

  public signin(): boolean {
    if (!this.parse_hash_string(window.location.hash)["access_token"]) {
      return true;
    } else {
      localStorage.setItem("customAuthFlag", "true");
    }

    this.findRedirectLocation();

    new Oidc.UserManager({ response_mode: "token" })
      .signinRedirectCallback()
      .then(function () {
        window.location.replace("/");
      })
      .catch(function (e) {
        console.error(e);
      });
    return false;
  }
}

let authService = new AuthService();

export default authService;
