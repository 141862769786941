import React, { useEffect, useState } from "react";
import "./Menu.css";
import SvgIcon from "../svg-icon/SvgIcon";
import Config, { ConfigTypes } from "services/ConfigService";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store/reducers";
import {
  updateJournalIsArchive,
  updateJournalList,
} from "store/journal/actions";
import { FilterParams } from "infrastructure/interfaces";
import {
  setFilterParams,
  updateMenuFieldPermissions,
} from "store/common/actions";
import { MENU_FIELD_PERMISSIONS } from "../../../infrastructure/enum/field-permissions.enum";
import {
  getCommonCompaniesCatalog,
  getCommonFilterParams,
  getCommonUserDetail,
} from "store/selectors";

interface StatusFilter {
  text: {
    en: string;
    ru: string;
  };
  value: string;
  code: string;
}

const Menu: React.FC = () => {
  const { t, i18n } = useTranslation();
  const userDetail = useSelector(getCommonUserDetail);
  const companyDetails = useSelector(getCommonCompaniesCatalog);
  const filterParams = useSelector(getCommonFilterParams);

  let [openRequestSubmenu, setOpenRequestSubmenu] = useState(false);
  let [openExpensesSubmenu, setOpenExpensesSubmenu] = useState(false);
  let [openStatusSubmenu, setOpenStatusSubmenu] = useState(false);
  let [openJournalSubmenu, setOpenJournalSubmenu] = useState(false);
  let [isArchiveActive, setIsArchiveActive] = useState(false);
  let [openAgreementSubmenu, setOpenAgreementSubmenu] = useState(false);
  let [openReportSubmenu, setOpenReportSubmenu] = useState(false);
  let [openSubstitutionSubmenu, setOpenSubstitutionSubmenu] = useState(false);
  const [isPowerOfAttorneyEnabled, setIsPowerOfAttorneyEnabled] =
    useState(false);

  function isPowerOfAttorneyEnabledFunction() {
    for (let i = 0; i < userDetail.occupations.length; i++) {
      if (
        Config.getConfigToCompany(
          ConfigTypes.POWER_OF_ATTORNEY_ENABLED,
          userDetail.occupations[i].company.id
        ) &&
        companyDetails.find(
          (el) => el.manager && el.manager.employee.id === userDetail.id
        )
      ) {
        setIsPowerOfAttorneyEnabled(true);
      }
    }
  }

  useEffect(() => {
    isPowerOfAttorneyEnabledFunction();
  }, []);

  let menuFieldPermissions = useSelector(
    (state: ApplicationState) => state.common.menuFieldPermissions
  );
  const journalFieldPermissions = useSelector(
    (state: ApplicationState) => state.journal.fieldPermissions
  );
  const journalIsArchive = useSelector(
    (state: ApplicationState) => state.journal.isArchive
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateMenuFieldPermissions());
  }, []);

  const checkOpenRequestSubmenu = (match = {}) => {
    setOpenRequestSubmenu(!!match);
    return !!match;
  };

  const checkOpenExpensesSubmenu = (match = {}) => {
    setOpenExpensesSubmenu(!!match);
    return !!match;
  };

  const checkOpenStatusSubmenu = (match = {}) => {
    setOpenStatusSubmenu(!!match);
    return !!match;
  };
  const checkOpenJournalSubmenu = (match = {}, location: any) => {
    let isJournalPageOpened = location.pathname.indexOf("/Journal") !== -1;
    if (isJournalPageOpened) {
      setOpenJournalSubmenu(
        (journalFieldPermissions &&
          journalFieldPermissions.includes("JournalArchive")) ||
          journalIsArchive
      );
    } else {
      setOpenJournalSubmenu(false);
    }
    return isJournalPageOpened;
  };

  const checkOpenReportsSubmenu = (match = {}, location: any) => {
    let check = location.pathname.indexOf("/Reports") !== -1;
    setOpenReportSubmenu(check);
    return check;
  };

  const checkOpenSubstitutionSubmenu = (match = {}, location: any) => {
    let check = location.pathname.indexOf("/Substitutions") !== -1;
    setOpenSubstitutionSubmenu(check);
    return check;
  };

  const checkOpenAgreementSubmenu = (match = {}) => {
    setOpenAgreementSubmenu(!!match);
    return !!match;
  };

  let configuredStatuses: StatusFilter[] = [
    {
      text: {
        ru: "Черновик",
        en: "Draft",
      },
      value: "0",
      code: "draft",
    },
    {
      text: {
        ru: "Требует согласования",
        en: "Approval Requires",
      },
      value: "1",
      code: "approval_requires",
    },
    {
      text: {
        ru: "На согласовании",
        en: "On Approval",
      },
      value: "2",
      code: "on_approval",
    },
    {
      text: {
        ru: "Согласован",
        en: "Approved",
      },
      value: "3",
      code: "approved",
    },
    {
      text: {
        ru: "Не согласован",
        en: "Not Approved",
      },
      value: "5",
      code: "not_approved",
    },
    {
      text: {
        ru: "Отозван",
        en: "Revoked",
      },
      value: "4",
      code: "revoked",
    },
    {
      text: {
        ru: "Проведен",
        en: "Posted",
      },
      value: "6",
      code: "posted",
    },
    {
      text: {
        ru: "Отменен",
        en: "Cancelled",
      },
      value: "7",
      code: "cancelled",
    },
    {
      text: {
        ru: "На доработке",
        en: "On Revision",
      },
      value: "9",
      code: "on_revision",
    },
  ];

  const configuredStatusesExpenses: StatusFilter[] = [
    {
      text: {
        ru: "Черновик",
        en: "Draft",
      },
      value: "0",
      code: "draft",
    },
    {
      text: {
        ru: "Требует согласования",
        en: "Approval Requires",
      },
      value: "1",
      code: "approval_requires",
    },
    {
      text: {
        ru: "На согласовании",
        en: "On Approval",
      },
      value: "2",
      code: "on_approval",
    },
    {
      text: {
        ru: "Согласована",
        en: "Approved",
      },
      value: "3",
      code: "approved",
    },
    {
      text: {
        ru: "Не согласована",
        en: "Not Approved",
      },
      value: "4",
      code: "not_approved",
    },
    {
      text: {
        ru: "Отозвана",
        en: "Revoked",
      },
      value: "5",
      code: "revoked",
    },
    {
      text: {
        ru: "Проведена",
        en: "Posted",
      },
      value: "6",
      code: "posted",
    },
    {
      text: {
        ru: "Отменена",
        en: "Cancelled",
      },
      value: "9",
      code: "cancelled",
    },
    {
      text: {
        ru: "На доработке",
        en: "On Revision",
      },
      value: "10",
      code: "on_revision",
    },
  ];

  const inHand = [
    {
      text: {
        ru: "Новые",
        en: "New",
      },
      value: "0",
      code: "new",
    },
    {
      text: {
        ru: "У меня в работе",
        en: "In my hand",
      },
      value: "1",
      code: "in_my_hand",
    },
    {
      text: {
        ru: "У других в работе",
        en: "In others hand",
      },
      value: "2",
      code: "in_others_hand",
    },
  ];

  const exspenseNavLinkClickHandler = (value: string) => {
    let params: FilterParams = {
      Status: [value],
    };
    dispatch(setFilterParams(params));
  };

  const AONavLinkClickHandler = (value: string) => {
    let params: FilterParams = {
      Status: [value],
    };
    dispatch(setFilterParams(params));
  };

  const journalNavLinkClickHandler = () => {
    setIsArchiveActive(false);
    dispatch(updateJournalIsArchive(false));
    dispatch(updateJournalList("AdvanceReportApplication", false));
  };

  const archiveNavLinkClickHandler = () => {
    setIsArchiveActive(true);
    dispatch(updateJournalIsArchive(true));
    dispatch(updateJournalList("AdvanceReportApplication", false));
  };

  const AgreementNavLinkClickHandler = (value: string) => {
    dispatch(setFilterParams((filterParams.WhoIsInPaperWork = [+value])));
  };

  const checkIsArchiveActive = () => {
    return isArchiveActive;
  };

  return (
    <aside className="sidebar-left">
      <nav className="sidebar-menu">
        <ul className="sidebar-menu__list">
          {menuFieldPermissions &&
          menuFieldPermissions.includes(
            MENU_FIELD_PERMISSIONS.EXPENSE_APPLICATION
          ) ? (
            <li className="sidebar-menu__item">
              <NavLink
                className="sidebar-menu__link"
                to="/ExpenseApplication"
                activeClassName="active"
                tabIndex={-1}
                isActive={checkOpenRequestSubmenu}
              >
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8334 1.83331H4.50008C4.01385 1.83331 3.54754 2.02647 3.20372 2.37028C2.8599 2.7141 2.66675 3.18042 2.66675 3.66665V18.3333C2.66675 18.8195 2.8599 19.2859 3.20372 19.6297C3.54754 19.9735 4.01385 20.1666 4.50008 20.1666H15.5001C15.9863 20.1666 16.4526 19.9735 16.7964 19.6297C17.1403 19.2859 17.3334 18.8195 17.3334 18.3333V7.33331L11.8334 1.83331Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 16.5V11"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.25 13.75H12.75"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.8333 1.83331V7.33331H17.3333"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t("menu.request")}
              </NavLink>
              <ul
                className={`sidebar-menu__list-submenu ${openRequestSubmenu ? "active-submenu-list" : ""}`}
              >
                {configuredStatusesExpenses.map((status, index) => (
                  <li key={index}>
                    <NavLink
                      to={`/ExpenseApplication/${status.code}`}
                      activeClassName="active"
                      tabIndex={-1}
                      onClick={() => exspenseNavLinkClickHandler(status.value)}
                      isActive={checkOpenStatusSubmenu}
                    >
                      {status.text[i18n.language as "en" | "ru"]}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          ) : null}
          {menuFieldPermissions &&
          menuFieldPermissions.includes(
            MENU_FIELD_PERMISSIONS.ADVANCE_REPORT_APPLICATION
          ) ? (
            <li className="sidebar-menu__item">
              <NavLink
                className="sidebar-menu__link"
                to="/AdvanceReportApplication"
                activeClassName="active"
                tabIndex={-1}
                isActive={checkOpenExpensesSubmenu}
              >
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8334 1.83331H4.50008C4.01385 1.83331 3.54754 2.02647 3.20372 2.37028C2.8599 2.7141 2.66675 3.18042 2.66675 3.66665V18.3333C2.66675 18.8195 2.8599 19.2859 3.20372 19.6297C3.54754 19.9735 4.01385 20.1666 4.50008 20.1666H15.5001C15.9863 20.1666 16.4526 19.9735 16.7964 19.6297C17.1403 19.2859 17.3334 18.8195 17.3334 18.3333V7.33331L11.8334 1.83331Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.6666 15.5833H6.33325"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.6666 11.9167H6.33325"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.16658 8.25H7.24992H6.33325"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.8333 1.83331V7.33331H17.3333"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t("menu.expenses")}
              </NavLink>
              <ul
                className={`sidebar-menu__list-submenu ${openExpensesSubmenu ? "active-submenu-list" : ""}`}
              >
                {configuredStatuses.map((status, index) => (
                  <li key={index}>
                    <NavLink
                      to={`/AdvanceReportApplication/${status.code}`}
                      activeClassName="active"
                      tabIndex={-1}
                      onClick={() => AONavLinkClickHandler(status.value)}
                      isActive={checkOpenStatusSubmenu}
                    >
                      {status.text[i18n.language as "en" | "ru"]}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          ) : null}
          {menuFieldPermissions &&
          menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.AGREEMENT) ? (
            <li className="sidebar-menu__item">
              <NavLink
                className="sidebar-menu__link"
                to="/Agreement"
                activeClassName="active"
                tabIndex={-1}
                isActive={checkOpenAgreementSubmenu}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.41658 20.1667H3.66659C3.18035 20.1667 2.71404 19.9735 2.37022 19.6297C2.02641 19.2859 1.83325 18.8196 1.83325 18.3333V11.9167C1.83325 11.4304 2.02641 10.9641 2.37022 10.6203C2.71404 10.2765 3.18035 10.0833 3.66659 10.0833H6.41658M12.8333 8.25001V4.58334C12.8333 3.854 12.5435 3.15453 12.0278 2.6388C11.5121 2.12307 10.8126 1.83334 10.0833 1.83334L6.41658 10.0833V20.1667H16.7566C17.1987 20.1717 17.6278 20.0167 17.9647 19.7303C18.3016 19.444 18.5236 19.0455 18.5899 18.6083L19.8549 10.3583C19.8948 10.0956 19.8771 9.8273 19.803 9.57207C19.7289 9.31685 19.6002 9.08078 19.4258 8.88023C19.2514 8.67968 19.0355 8.51945 18.793 8.41063C18.5506 8.30181 18.2873 8.247 18.0216 8.25001H12.8333Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t("menu.analytics")}
              </NavLink>
              <ul
                className={`sidebar-menu__list-submenu ${openAgreementSubmenu ? "active-submenu-list" : ""}`}
              >
                {inHand.map((status, index) => (
                  <li key={index}>
                    <NavLink
                      to={`/Agreement/${status.code}`}
                      activeClassName="active"
                      tabIndex={-1}
                      onClick={() => AgreementNavLinkClickHandler(status.value)}
                      isActive={checkOpenStatusSubmenu}
                    >
                      {status.text[i18n.language as "en" | "ru"]}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          ) : null}
          {menuFieldPermissions &&
          menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.JOURNAL) ? (
            <li className="sidebar-menu__item">
              <NavLink
                className="sidebar-menu__link"
                to="/Journal/AdvanceReportApplication"
                tabIndex={-1}
                isActive={(match, location) =>
                  checkOpenJournalSubmenu(match, location)
                }
                onClick={journalNavLinkClickHandler}
                activeClassName="active"
              >
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M5.41667 21.0833L1.75 17.4167L5.41667 13.75"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.25 11.9167V13.75C18.25 14.7225 17.8637 15.6551 17.1761 16.3427C16.4884 17.0304 15.5558 17.4167 14.5833 17.4167H1.75"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.5833 0.916672L18.2499 4.58334L14.5833 8.25001"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.75 10.0833V8.25C1.75 7.27753 2.13631 6.3449 2.82394 5.65727C3.51158 4.96964 4.44421 4.58333 5.41667 4.58333H18.25"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="22"
                        height="22"
                        fill="white"
                        transform="translate(-1)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {t("menu.journal")}
              </NavLink>
              <ul
                className={`sidebar-menu__list-submenu ${openJournalSubmenu ? "active-submenu-list" : ""}`}
              >
                <li>
                  <NavLink
                    to="/Journal/AdvanceReportApplication"
                    activeClassName={true ? "" : "sub_active"}
                    tabIndex={-1}
                    onClick={archiveNavLinkClickHandler}
                    isActive={checkIsArchiveActive}
                  >
                    {t("menu.archive_journal")}
                  </NavLink>
                </li>
              </ul>
            </li>
          ) : null}
          {menuFieldPermissions &&
          menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.REPORTS) ? (
            <li className="sidebar-menu__item">
              <NavLink
                className="sidebar-menu__link"
                to="/Reports"
                isActive={(match, location) =>
                  checkOpenReportsSubmenu(match, location)
                }
                activeClassName="active"
              >
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 4H17C17.5304 4 18.0391 4.21071 18.4142 4.58579C18.7893 4.96086 19 5.46957 19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4H7"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 2H8C7.44772 2 7 2.44772 7 3V5C7 5.55228 7.44772 6 8 6H14C14.5523 6 15 5.55228 15 5V3C15 2.44772 14.5523 2 14 2Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t("menu.reports")}
              </NavLink>
              <ul
                className="sidebar-menu__list-submenu"
                style={{ display: openReportSubmenu ? "block" : "none" }}
              >
                <li>
                  <NavLink
                    to="/Reports"
                    activeClassName={true ? "" : "sub_active"}
                    onClick={archiveNavLinkClickHandler}
                    isActive={checkIsArchiveActive}
                  >
                    {t("menu.reports_registry")}
                  </NavLink>
                </li>
              </ul>
            </li>
          ) : null}

          {isPowerOfAttorneyEnabled ||
          menuFieldPermissions.includes(
            MENU_FIELD_PERMISSIONS.POWER_OF_ATTORNEY_MANAGER
          ) ? (
            <li className="sidebar-menu__item">
              <NavLink
                className="sidebar-menu__link"
                to="/PowersOfAttorney"
                activeClassName="active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M4.00002 4.50008H4.50002V4.00008V3.16675C4.50002 2.63632 4.71073 2.12761 5.08581 1.75253C5.46088 1.37746 5.96959 1.16675 6.50002 1.16675H14.8334C15.3638 1.16675 15.8725 1.37746 16.2476 1.75253C16.6226 2.12761 16.8334 2.63631 16.8334 3.16675V11.5001C16.8334 12.0305 16.6226 12.5392 16.2476 12.9143C15.8725 13.2894 15.3638 13.5001 14.8334 13.5001H14H13.5V14.0001V14.8334C13.5 15.3638 13.2893 15.8726 12.9142 16.2476C12.5392 16.6227 12.0305 16.8334 11.5 16.8334H3.16669C2.63625 16.8334 2.12755 16.6227 1.75247 16.2476C1.3774 15.8726 1.16669 15.3638 1.16669 14.8334V6.50008C1.16669 5.96965 1.3774 5.46094 1.75247 5.08587C2.12755 4.7108 2.63625 4.50008 3.16669 4.50008H4.00002ZM2.33335 8.50008H1.83335V9.00008V14.8334C1.83335 15.187 1.97383 15.5262 2.22388 15.7762C2.47393 16.0263 2.81307 16.1667 3.16669 16.1667H11.5C11.8536 16.1667 12.1928 16.0263 12.4428 15.7762C12.6929 15.5262 12.8334 15.187 12.8334 14.8334V9.00008V8.50008H12.3334H2.33335ZM12.3334 7.83342H12.8334V7.33342V6.50008C12.8334 6.14646 12.6929 5.80732 12.4428 5.55727C12.1928 5.30722 11.8536 5.16675 11.5 5.16675H3.16669C2.81306 5.16675 2.47393 5.30722 2.22388 5.55727C1.97383 5.80732 1.83335 6.14646 1.83335 6.50008V7.33342V7.83342H2.33335H12.3334ZM13.5 12.3334V12.8334H14H14.8334C15.187 12.8334 15.5261 12.6929 15.7762 12.4429C16.0262 12.1928 16.1667 11.8537 16.1667 11.5001V5.66675V5.16675H15.6667H13.85H13.1366L13.38 5.83738C13.4574 6.05059 13.498 6.27541 13.5 6.50222V12.3334ZM15.6667 4.50008H16.1667V4.00008V3.16675C16.1667 2.81313 16.0262 2.47399 15.7762 2.22394C15.5261 1.97389 15.187 1.83341 14.8334 1.83341H6.50002C6.1464 1.83341 5.80726 1.97389 5.55721 2.22394C5.30716 2.47399 5.16669 2.81313 5.16669 3.16675V4.00008V4.50008H5.66669H15.6667Z"
                    fill="#757575"
                    stroke="black"
                  />
                </svg>
                {t("menu.powers_of_attorney")}
              </NavLink>
            </li>
          ) : null}
          {menuFieldPermissions &&
            menuFieldPermissions.includes(
              MENU_FIELD_PERMISSIONS.SUBSTITUTION_MANAGER
            ) && (
              <li className="sidebar-menu__item">
                <NavLink
                  className="sidebar-menu__link"
                  to="/Substitutions"
                  isActive={(match, location) =>
                    checkOpenSubstitutionSubmenu(match, location)
                  }
                  activeClassName="active"
                >
                  <SvgIcon
                    href="#svg_icon_profile-1"
                    className="icon"
                    style={{
                      stroke: openSubstitutionSubmenu ? "red" : "black",
                      width: "auto",
                    }}
                  />
                  {t("menu.substitution")}
                </NavLink>
              </li>
            )}
        </ul>
      </nav>
    </aside>
  );
};

export default Menu;
