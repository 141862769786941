import styled from "styled-components";
import { ExpireProps } from "../types";

export const ListContainer = styled.div`
  @media screen and (max-width: 650px) {
    width: 90%;
  }
  @media screen and (max-width: 550px) {
    width: 80%;
  }
  @media screen and (max-width: 450px) {
    width: 70%;
  }
  @media screen and (max-width: 400px) {
    width: 60%;
  }
  @media screen and (max-width: 340px) {
    width: 50%;
  }
`;

export const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
`;
export const PersonalAccount = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #9e9e9e;
  margin: 9px 0 17px;
`;

export const ButtomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  @media screen and (max-width: 480px) {
    margin-top: 10px;
    width: 100%;
  }
`;

export const AddButton = styled.button`
  display: inline-flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  background: #4cd964;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ExpireWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #c5ccd0;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  padding: 4px 10px 5px;
  margin-left: 2px;
  white-space: nowrap;
`;

export const Expire = styled.div<ExpireProps>`
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: ${(props) => (props.expired ? "#E00000" : "#0a0a0a")};
`;

export const PowerContent = styled.div`
  justify-content: flex-start;
  @media screen and (max-width: 740px) {
    margin-top: 12px;
  }
`;

export const PowerOfAttorney = styled.div`
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
  margin-bottom: 12px;
  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

export const PowerInfo = styled.div`
  display: inline-flex;
  min-width: 500px;
  margin-top: 12px;
  @media screen and (max-width: 740px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    margin-top: 29px;
  }
`;

export const PowerInfoName = styled.div`
  flex-direction: column;
  width: 250px;
  margin: 0 20px 0 0;
  @media screen and (max-width: 1230px) {
    width: 200px;
  }
  @media screen and (max-width: 1100px) {
    width: 150px;
  }
  @media screen and (max-width: 740px) {
    margin-bottom: 12px;
    width: 100%;
  }
`;

export const Label = styled.div`
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #98a2a8;
`;

export const Info = styled.div`
  width: 80%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4d5a61;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    width: 70%;
  }
  @media screen and (max-width: 400px) {
    width: 60%;
  }
  @media screen and (max-width: 340px) {
    width: 45%;
  }
`;

export const InfoCode = styled.span`
  font-weight: 600;
`;

export const Authorities = styled.div`
  flex-direction: column;
  width: 700px;
  @media screen and (max-width: 1230px) {
    width: 600px;
  }
  @media screen and (max-width: 1045px) {
    width: 550px;
  }
  @media screen and (max-width: 740px) {
    width: 100%;
  }
`;

export const PowerStatus = styled.div`
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 18px;
  margin: 32px 27px;
  align-items: center;
  @media screen and (max-width: 740px) {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-top: 12px;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 740px) {
    margin: 0;
  }
`;

export const Connected = styled.div`
  @media screen and (max-width: 480px) {
    visibility: hidden;
    position: absolute;
  }
`;

export const Button = styled.button`
  position: absolute;
  bottom: 10px;
  right: 17px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: 99px;
  height: 32px;
  background: #5d6c74;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  @media screen and (max-width: 740px) {
    position: relative;
    bottom: 0;
    right: 0;
    margin-top: 10px;
    width: 100%;
  }
`;

export const Array = styled.div`
  position: absolute;
  right: 17px;
  top: 38px;
  display: flex;
  @media screen and (max-width: 740px) {
    top: 48px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 30px 24px 12px;
  width: 100%;
  height: 142px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover ${PowerOfAttorney} {
    color: #98a2a8;
  }
  &:hover ${Info} {
    color: #98a2a8;
  }

  @media screen and (max-width: 740px) {
    height: auto;
    flex-direction: column;
  }
`;
