import React, { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import EmployeeAutocomplete, {
  EmployeeAutocompleteOptionType,
} from "app/component/autocomplete/EmployeeAutocomplete";
import {
  checkDuplicatePowerOfAttorney,
  getEmployeesById,
  getOccupationsShortByEmployeeId,
  getPowerOfAttorneyAuthorities,
} from "services/ApiService";
import { getCommonUserDetail, getPowerOfAttorneyDetail } from "store/selectors";
import ModalContainer from "ui/Modal/ModalContainer";
import { SvgIcon } from "ui/SvgIcon";
import CertificatePower from "./PowerOfAttorneyDialogStep3";
import { showErrors } from "store/exception/actions";
import TrustedPersonDetails from "./ui/TrustedPersonDetails";
import Authorities from "./ui/Authorities";
import {
  BackButton,
  BoxModal,
  BoxModalContent,
  BoxModalFooter,
  BoxModalHeader,
  ButtonSaveContainer,
  Input,
  NextButton,
  PopupBackground,
  PopupButton,
  PopupButtonContainer,
  PopupButtons,
  Status,
  SubTitle,
  Textarea,
  UpperLabel,
} from "./PowerOfAttorneyCommonStyled";
import Duplicate from "./ui/Duplicate";
import {
  saveAndSendForSigning,
  saveDraft,
  updateAndChangeStatus,
  updateAndSave,
} from "store/power/actions";
import { DialogStep2Props } from "../types";
import { PowerOfAttorneyAuthorityItem } from "infrastructure/interfaces";
import HoldingCompanies from "./ui/HoldingCompanies";
import { ApplicationState } from "store/reducers";
import { MENU_FIELD_PERMISSIONS } from "infrastructure/enum/field-permissions.enum";
import { CompanyAutocompleteOptionType } from "app/component/autocomplete/CompanyAutocomplete";

const PowerOfAttorneyDialogStep2: React.FC<DialogStep2Props> = ({
  setContine,
  companyId,
  validTo,
  ownerCompanyManager,
  isModalToCreate,
  setIsModalToCreate,
  isModalToEdit,
  setIsModalToEdit,
}) => {
  let { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const userDetail = useSelector(getCommonUserDetail);
  const POW = useSelector(getPowerOfAttorneyDetail);
  const menuFieldPermissions = useSelector(
    (state: ApplicationState) => state.common.menuFieldPermissions
  );

  const [name, setName] = useState("");
  const [occupation, setOccupation] = useState<any>();
  const [inn, setInn] = useState("");
  const [snils, setSnils] = useState("");
  const [passport, setPassport] = useState("");
  const [issuedOn, setIssuedOn] = useState("");
  const [issuedByCode, setIssuedByCode] = useState("");
  const [issuedBy, setissuedBy] = useState("");
  const [citizenshipCountry, setCitizenshipCountry] = useState("");
  const [isManager, setIsManager] = useState(true);
  const [isPowerOfAttorneyManager, setIsPowerOfAttorneyManager] =
    useState(true);
  const [employee, setEmployee] = useState(
    {} as EmployeeAutocompleteOptionType
  );
  const [authorities, setAuthorities] = useState<
    PowerOfAttorneyAuthorityItem[]
  >([]);
  const [authorityChosen, setAuthorityChosen] = useState<
    PowerOfAttorneyAuthorityItem[]
  >([]);
  const [isOpenDropDown, setOpenDropDown] = useState(false);
  const [disabledToContinue, setDisabledToContinue] = useState(true);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [isOpenDrop, setOpenDrop] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [rows, setRows] = useState(1);
  const [cert, setCert] = useState(false);
  const [signingBlobFileId, setSigningBlobFileId] = useState(0);
  const [powerOfAttorneyId, setPowerOfAttorneyId] = useState(0);
  const [modaldDuplicate, setModalDuplicate] = useState({
    status: false,
    action: "",
    id: 0,
  });
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const [company, setCompany] = useState({} as CompanyAutocompleteOptionType);

  const employeeAutocompleteRef = useRef({} as any);
  const companyAutocompleteRef = useRef<any>(null);

  const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
    if (option.value !== employee.value) {
      setEmployee(option);
    }
  };

  console.log(POW);

  useEffect(() => {
    if (!isModalToCreate) {
      setAuthorityChosen(POW.authorities);
      setEmployee({
        value: POW.representative.employee.id,
        text: POW.representative.employee.fullName.ru,
      });
      setCompany({
        value: POW.representative.company.id,
        text: POW.representative.company.name.ru,
      });
    }
  }, [POW, isModalToCreate]);

  useEffect(() => {
    if (!authorities.length) {
      getAuthoritiesList();
    }
  }, []);

  const isEditedByManager = () => {
    if (userDetail.id === ownerCompanyManager) {
      setIsManager(true);
    } else {
      setIsManager(false);
    }

    if (
      menuFieldPermissions.includes(
        MENU_FIELD_PERMISSIONS.POWER_OF_ATTORNEY_MANAGER
      )
    ) {
      setIsPowerOfAttorneyManager(true);
    } else {
      setIsPowerOfAttorneyManager(false);
    }
  };

  useEffect(() => {
    isEditedByManager();
  }, [isManager, isPowerOfAttorneyManager]);

  const click = () => {
    setContine(false);
  };

  useEffect(() => {
    if (
      inn !== "" &&
      snils !== "" &&
      passport !== "" &&
      issuedOn !== "" &&
      citizenshipCountry !== "" &&
      issuedBy !== "" &&
      authorityChosen.length > 0
    ) {
      setDisabledToContinue(false);
    } else {
      setDisabledToContinue(true);
    }
  }, [
    authorityChosen,
    authorities,
    inn,
    snils,
    passport,
    issuedOn,
    citizenshipCountry,
    issuedBy,
  ]);
  const getEmployeeById = async (id: number) => {
    try {
      const result = await getEmployeesById(id);
      if (result.headers.success && result.data) {
        setName(result.data.name[i18n.language as "ru" | "en"] || "");
        setSnils(result.data.snils || "");
        setInn(result.data.inn || "");
        if (result.data.documents.length) {
          setPassport(
            `${result.data.documents[0].series}  ${result.data.documents[0].number}`
          );
          setIssuedOn(
            moment(result.data.documents[0].issuedOn.slice(0, 10))
              .utc()
              .format("DD.MM.YYYY")
          );
          setIssuedByCode(result.data.documents[0].issuedByCode);
          setissuedBy(result.data.documents[0].issuedBy);
          setCitizenshipCountry(
            result.data.documents[0].citizenshipCountry.name[
              i18n.language as "ru" | "en"
            ]
          );
        } else {
          setPassport("");
          setIssuedOn("");
          setIssuedByCode("");
          setissuedBy("");
          setCitizenshipCountry("");
        }
        setIsUserSelected(true);
      }
    } catch (error) {
      dispatch(
        showErrors({
          code: "",
          message: "Не удалось загрузить информацию о сотруднике",
        })
      );
    }
  };

  useEffect(() => {
    if (!isManager && !isPowerOfAttorneyManager) {
      getEmployeeById(userDetail.id);
      getEmployeesPosition(userDetail.id, company ? company.value : companyId);
    }
    if (
      (isManager || isPowerOfAttorneyManager) &&
      Object.keys(employee).length !== 0
    ) {
      getEmployeeById(employee.value);
      getEmployeesPosition(employee.value, company ? company.value : companyId);
    }
  }, [employee, isManager, isPowerOfAttorneyManager]);

  const clearFields = () => {
    if (!isModalToCreate) {
      employeeAutocompleteRef.current.clear();
      employeeChooseHandler({ text: "", value: 0 });
      setSnils("");
      setInn("");
      setPassport("");
      setIssuedOn("");
      setIssuedByCode("");
      setissuedBy("");
      setCitizenshipCountry("");
      setIsUserSelected(false);
      setOccupation((prev) => ({ ...prev, value: null }));
    }
  };

  const getEmployeesPosition = async (userId: number, companyId: number) => {
    try {
      let result = await getOccupationsShortByEmployeeId(userId, companyId);
      if (result.headers.success) {
        let newOccupations = result.data;
        setOccupation(newOccupations[0]);
      }
    } catch (error) {
      dispatch(
        showErrors({
          code: "",
          message: "Не удалось загрузить информацию о должности сотрудника",
        })
      );
    }
  };

  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (screenWidth >= 980) {
      setRows(1);
    }
    if (screenWidth <= 640) {
      setRows(1);
    }
    if (screenWidth < 493 && screenWidth > 480) {
      setRows(2);
    }
    if (screenWidth < 440) {
      setRows(2);
    }
  }, [screenWidth]);

  const getAuthoritiesList = async () => {
    try {
      const result = await getPowerOfAttorneyAuthorities();
      if (result.headers.success) {
        if (isModalToCreate) {
          setAuthorities(result.data);
        }

        if (!isModalToCreate) {
          let resultArray = [...result.data, ...POW.authorities];
          let res = resultArray.filter(
            (item) =>
              !(
                POW.authorities.some((elem) => elem.code === item.code) &&
                result.data.some((elem) => elem.code === item.code)
              )
          );

          setAuthorities(res);
        }
      }
    } catch (error) {}
  };

  const toggle = () => {
    if (setIsModalToCreate) {
      setIsModalToCreate(!isModalToCreate);
    }
    if (setIsModalToEdit) {
      setIsModalToEdit(!isModalToEdit);
    }
  };

  const authorityIds = authorityChosen.map((el) => el.id);
  const data = {
    ownerCompanyId: companyId,
    ownerCompanyManagerEmployeeId: ownerCompanyManager,
    representativeOccupationId: occupation ? occupation.id : "",
    validTo: new Date(validTo).toISOString(),
    authorityIds: authorityIds,
  };

  // CHECK & DRAFT
  const savePowerOfAttorneyHandler = () => {
    if (isRequestProcessing) {
      return;
    }
    setIsRequestProcessing(true);
    checkDuplicatePowerOfAttorney({
      ...data,
      representativeCompanyId: company.value,
    }).then((res) => {
      if (res.data.duplicatesFound) {
        setOpenDrop(false);
        setIsRequestProcessing(false);
        setModalDuplicate({
          status: true,
          action: "Draft",
          id: res.data.duplicatePowerOfAttorneys[0].id,
        });
      } else {
        setIsModalToCreate &&
          dispatch(
            saveDraft(
              { ...data, representativeCompanyId: company.value },
              isManager,
              setIsModalToCreate,
              setIsRequestProcessing
            )
          );
      }
    });
  };

  // CHECK & DRAFT & SIGNING REQUIRES
  const changeStatusOfPowerOfAttorneyHandler = () => {
    if (isRequestProcessing) {
      return;
    }
    setIsRequestProcessing(true);
    checkDuplicatePowerOfAttorney({
      ...data,
      representativeCompanyId: company.value,
    }).then((res) => {
      if (res.data.duplicatesFound) {
        setIsRequestProcessing(false);
        setOpenDrop(false);
        setModalDuplicate({
          status: true,
          action: "SigningRequires",
          id: res.data.duplicatePowerOfAttorneys[0].id,
        });
      } else {
        setIsModalToCreate &&
          dispatch(
            saveAndSendForSigning(
              { ...data, representativeCompanyId: company.value },
              setPowerOfAttorneyId,
              setSigningBlobFileId,
              isManager,
              setIsModalToCreate,
              setCert,
              setIsRequestProcessing
            )
          );
      }
    });
  };

  // UPDATE & CHECK & DRAFT
  const updatePowerOfAttorneyHandler = () => {
    if (isRequestProcessing) {
      return;
    }
    setIsRequestProcessing(true);
    checkDuplicatePowerOfAttorney({
      ...data,
      representativeCompanyId: company.value,
    }).then((res) => {
      if (res.data.duplicatesFound) {
        setIsRequestProcessing(false);
        setOpenDrop(false);
        setModalDuplicate({
          status: true,
          action: "Draft",
          id: res.data.duplicatePowerOfAttorneys[0].id,
        });
      } else {
        setIsModalToEdit &&
          dispatch(
            updateAndSave(
              POW,
              { ...data, representativeCompanyId: company.value },
              isManager,
              setIsModalToEdit,
              setIsRequestProcessing
            )
          );
      }
    });
  };

  // UPDATE & CHECK & DRAFT & SIGNING REQUIRES
  const updateAndChangeStatusOfPowerOfAttorneyHandler = () => {
    if (isRequestProcessing) {
      return;
    }
    setIsRequestProcessing(true);
    checkDuplicatePowerOfAttorney({
      ...data,
      representativeCompanyId: company.value,
    }).then((res) => {
      if (res.data.duplicatesFound) {
        setIsRequestProcessing(false);
        setOpenDrop(false);
        setModalDuplicate({
          status: true,
          action: "SigningRequires",
          id: res.data.duplicatePowerOfAttorneys[0].id,
        });
      } else {
        setIsModalToEdit &&
          dispatch(
            updateAndChangeStatus(
              POW,
              { ...data, representativeCompanyId: company.value },
              setIsModalToEdit,
              setIsRequestProcessing
            )
          );
      }
    });
  };

  const handleDrop = () => {
    setOpenDrop(!isOpenDrop);
  };

  return (
    <>
      {modaldDuplicate.status && (
        <Duplicate
          setModalDuplicate={setModalDuplicate}
          modaldDuplicate={modaldDuplicate}
          data={{ ...data, representativeCompanyId: company.value }}
          setIsModalToCreate={setIsModalToCreate}
          isManager={isManager}
          setCert={setCert}
          setPowerOfAttorneyId={setPowerOfAttorneyId}
          setSigningBlobFileId={setSigningBlobFileId}
          setIsModalToEdit={setIsModalToEdit}
        />
      )}
      {!cert ? (
        <Fragment>
          <ModalContainer
            style={{ alignItems: "center" }}
            styleForDialog={true}
            isOpen={isModalToCreate || isModalToEdit}
          >
            <BoxModal>
              <BoxModalHeader>
                <div>{t("power.of_attorney")}</div>
                <div style={{ marginBottom: "6px" }}>
                  {POW ? (
                    <Status draft={POW.state !== "Declined" ? true : false}>
                      {POW.state === "Declined"
                        ? t("power.declined").toLocaleUpperCase()
                        : t("power.draft")}
                    </Status>
                  ) : (
                    <Status draft={true}>{t("power.draft")}</Status>
                  )}
                </div>
                <div className="box-modal_close" onClick={toggle}></div>
              </BoxModalHeader>
              <BoxModalContent>
                <SubTitle>{t("power.issued_to")}</SubTitle>
                {(isManager || isPowerOfAttorneyManager) && (
                  <HoldingCompanies
                    clearFields={clearFields}
                    ref={companyAutocompleteRef}
                    companyId={companyId}
                    company={company}
                    setCompany={setCompany}
                  />
                )}
                <UpperLabel>{t("power.employee_full_name")}</UpperLabel>
                {isManager || isPowerOfAttorneyManager ? (
                  <div className="input-item">
                    <div className="input-search">
                      <EmployeeAutocomplete
                        defaultText={
                          isModalToEdit && POW
                            ? POW.representative.employee.fullName[
                                i18n.language as "ru" | "en"
                              ]
                            : undefined
                        }
                        dontLoop={false}
                        ref={employeeAutocompleteRef}
                        onChoose={employeeChooseHandler}
                        placeholder={t("filters.enter_name")}
                        companyId={company ? company.value : companyId}
                        occupationId={company.value}
                      />
                    </div>
                  </div>
                ) : (
                  <Textarea rows={rows} disabled={true} value={name}></Textarea>
                )}
                <UpperLabel>{t("power.employee's_position")}</UpperLabel>
                <Input
                  value={
                    occupation && occupation.name
                      ? occupation.name[i18n.language as "ru" | "en"]
                      : ""
                  }
                  disabled={true}
                  placeholder={""}
                />
                <TrustedPersonDetails
                  inn={inn}
                  snils={snils}
                  name={name}
                  passport={passport}
                  issuedOn={issuedOn}
                  issuedByCode={issuedByCode}
                  citizenshipCountry={citizenshipCountry}
                  issuedBy={issuedBy}
                  rows={rows}
                  isUserSelected={isUserSelected}
                />
                <Authorities
                  authorityChosen={authorityChosen}
                  setAuthorityChosen={setAuthorityChosen}
                  setAuthorities={setAuthorities}
                  isOpenDropDown={isOpenDropDown}
                  authorities={authorities}
                  setOpenDropDown={setOpenDropDown}
                />
              </BoxModalContent>
              <BoxModalFooter>
                <BackButton onClick={click}>
                  {<SvgIcon id={"vectorLeft"} />} {t("power.button.back")}
                </BackButton>
                {isManager ? (
                  <NextButton
                    onClick={() => {
                      isModalToEdit
                        ? updateAndChangeStatusOfPowerOfAttorneyHandler()
                        : changeStatusOfPowerOfAttorneyHandler();
                    }}
                    disabled={disabledToContinue}
                    longWidth={true}
                  >
                    {t("power.button.save_and_сontinue")}{" "}
                    {
                      <SvgIcon
                        id={"vectorRight"}
                        color={disabledToContinue ? "#9E9E9E" : "white"}
                      />
                    }
                  </NextButton>
                ) : (
                  <ButtonSaveContainer>
                    <NextButton
                      disabled={disabledToContinue}
                      onClick={handleDrop}
                      style={{ width: "143px" }}
                    >
                      {t("power.button.save")}
                      {
                        <SvgIcon
                          id={"vector"}
                          color={disabledToContinue ? "#9E9E9E" : "white"}
                        />
                      }
                    </NextButton>
                    <PopupButtonContainer>
                      {isOpenDrop && (
                        <PopupBackground>
                          <PopupButtons>
                            <PopupButton
                              onClick={() => {
                                isModalToEdit
                                  ? updatePowerOfAttorneyHandler()
                                  : savePowerOfAttorneyHandler();
                              }}
                            >
                              {t("power.button.save")}
                            </PopupButton>
                            <PopupButton
                              onClick={() => {
                                isModalToEdit
                                  ? updateAndChangeStatusOfPowerOfAttorneyHandler()
                                  : changeStatusOfPowerOfAttorneyHandler();
                              }}
                            >
                              {t("power.button.save_and_send")}
                            </PopupButton>
                          </PopupButtons>
                        </PopupBackground>
                      )}
                    </PopupButtonContainer>
                  </ButtonSaveContainer>
                )}
              </BoxModalFooter>
            </BoxModal>
          </ModalContainer>
        </Fragment>
      ) : (
        <CertificatePower
          powerOfAttorneyId={powerOfAttorneyId}
          signingBlobFileId={signingBlobFileId}
          isManager={isManager}
          setCert={setCert}
          setIsModalToCreate={setIsModalToCreate}
          isModalToCreate={isModalToCreate}
          setIsModalToEdit={setIsModalToEdit}
        />
      )}
    </>
  );
};

export default PowerOfAttorneyDialogStep2;
