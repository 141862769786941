import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store/reducers";
import { Switch, Route } from "react-router";
import { NavLink, Redirect } from "react-router-dom";
import JournalExpense from "./JournalExpense";
import JournalReport from "./JournalReport";
import { useTranslation } from "react-i18next";
import JournalDownloads from "./JournalDownloads";
import If from "app/component/util/If";
import { getJournalIsArchive } from "../../../store/selectors";
import {
  setJournalIsArchive,
  updateJournalFieldPermissions,
} from "../../../store/journal/actions";
import { JOURNAL_FIELD_PERMISSIONS } from "../../../infrastructure/enum/field-permissions.enum";
import JournalDetailExpense from "./JournalDetailExpense";
import JournalDetailReport from "./JournalDetailReport";
import JournalDetailDownloads from "./JournalDetailDownloads";

const Journal: React.FC = () => {
  const { t } = useTranslation();

  let [openReport, setOpenReports] = useState(false);
  let [openExpense, setOpenExpense] = useState(false);
  let [openDownloads, setOpenDownloads] = useState(false);

  const isArchive: boolean = useSelector(getJournalIsArchive);
  const fieldPermissions: string[] = useSelector(
    (state: ApplicationState) => state.journal.fieldPermissions,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateJournalFieldPermissions());
    return function setNotIsArchive() {
      dispatch(setJournalIsArchive(false));
    };
  }, []);

  const checkOpenReport = (match = {}) => {
    setOpenReports(!!match);
    return !!match;
  };

  const checkOpenExpense = (match = {}) => {
    setOpenExpense(!!match);
    return !!match;
  };

  const checkOpenDownloads = (match = {}) => {
    setOpenDownloads(!!match);
    return !!match;
  };

  return (
    <Fragment>
      <div className="request journal-swap">
        <div className="request-menu">
          <ul>
            <If
              condition={
                fieldPermissions &&
                fieldPermissions.includes(
                  JOURNAL_FIELD_PERMISSIONS.ADVANCE_REPORT_APPLICATION,
                )
              }
            >
              <li className={openReport ? "active" : ""}>
                <NavLink
                  to="/Journal/AdvanceReportApplication"
                  isActive={checkOpenReport}
                >
                  {t("journal.advance_reports")}
                </NavLink>
              </li>
            </If>
            <If
              condition={
                fieldPermissions &&
                fieldPermissions.includes(
                  JOURNAL_FIELD_PERMISSIONS.EXPENSE_APPLICATION,
                )
              }
            >
              <li className={openExpense ? "active" : ""}>
                <NavLink
                  to="/Journal/ExpenseApplication"
                  isActive={checkOpenExpense}
                >
                  {t("journal.expenses")}
                </NavLink>
              </li>
            </If>
            <If
              condition={
                isArchive &&
                fieldPermissions &&
                fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.DOWNLOADS)
              }
            >
              <li className={openDownloads ? "active" : ""}>
                <NavLink to="/Journal/Downloads" isActive={checkOpenDownloads}>
                  {t("journal.downloads")}
                </NavLink>
              </li>
            </If>
          </ul>
        </div>
        <Switch>
          <Route exact path={"/Journal/ExpenseApplication"}>
            {fieldPermissions &&
            fieldPermissions.includes(
              JOURNAL_FIELD_PERMISSIONS.EXPENSE_APPLICATION,
            ) ? (
              <JournalExpense />
            ) : fieldPermissions ? (
              <Redirect to={"/Journal"} />
            ) : null}
          </Route>
          <Route path={"/Journal/ExpenseApplication/:id"}>
            {fieldPermissions &&
            fieldPermissions.includes(
              JOURNAL_FIELD_PERMISSIONS.EXPENSE_APPLICATION,
            ) ? (
              <JournalDetailExpense />
            ) : fieldPermissions ? (
              <Redirect to={"/Journal"} />
            ) : null}
          </Route>
          <Route exact path={"/Journal/AdvanceReportApplication"}>
            {fieldPermissions &&
            fieldPermissions.includes(
              JOURNAL_FIELD_PERMISSIONS.ADVANCE_REPORT_APPLICATION,
            ) ? (
              <JournalReport />
            ) : fieldPermissions ? (
              <Redirect to={"/Journal"} />
            ) : null}
          </Route>
          <Route path={"/Journal/AdvanceReportApplication/:id"}>
            {fieldPermissions &&
            fieldPermissions.includes(
              JOURNAL_FIELD_PERMISSIONS.ADVANCE_REPORT_APPLICATION,
            ) ? (
              <JournalDetailReport />
            ) : fieldPermissions ? (
              <Redirect to={"/Journal"} />
            ) : null}
          </Route>
          <Route exact path={"/Journal/Downloads"}>
            {isArchive &&
            fieldPermissions &&
            fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.DOWNLOADS) ? (
              <JournalDownloads />
            ) : fieldPermissions ? (
              <Redirect to={"/Journal"} />
            ) : null}
          </Route>
          <Route path={"/Journal/Downloads/:id"}>
            {isArchive &&
            fieldPermissions &&
            fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.DOWNLOADS) ? (
              <JournalDetailDownloads />
            ) : fieldPermissions ? (
              <Redirect to={"/Journal"} />
            ) : null}
          </Route>
        </Switch>
      </div>
    </Fragment>
  );
};

export default Journal;
