import React, { useEffect, useState } from "react";
import "./AOExpenses.css";
import { useTranslation } from "react-i18next";
import DeleteExpenseModal from "../../component/modal/DeleteExpenseModal";
import { IAO, IOccupations } from "../../../infrastructure/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { OCCUPATION_ROLE_TYPE } from "../../../infrastructure/enum/user-role.enum";
import { get } from "lodash";
import { loaderLock, loaderUnlock } from "../../../store/common/actions";
import Svgicon from "ui/SvgIcon/SvgIcon";
import ADActModal from "app/component/modal/ADActModal";
import { STATUS_TYPE } from "infrastructure/enum/status-type.enum";
import agreementService from "services/entity/agreement.service";
import { ADVANCE_APPLICATION_PROPETIES } from "../../../infrastructure/enum/object-properties.enum";
import {
  getAdvanceReportApplication,
  getCommonUserDetail,
} from "../../../store/selectors";
import { getAssistancesById } from "../../../services/ApiService";
import { updateDetailedAO } from "../../../store/report/actions";
import MemoModal from "app/component/modal/MemoModal/MemoModal";
import {
  DropdownButtonContainer,
  DropdownButtonItem,
} from "app/component/modal/MemoModal/MemoModalStyled";
import AODocument from "./AODocument";

interface AOListDocumentsProps {
  documentArr: any[];
}

const AODocuments: React.FC<AOListDocumentsProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication,
  );
  const user = useSelector(getCommonUserDetail);

  let [isOpenDeleteExpenseModal, setOpenDeleteExpenseModal] = useState(false);
  let [actId, setActId] = useState(0);
  let [isOpenActModal, setOpenActModal] = useState(false);
  const [isOpenMemoModal, setOpenMemoModal] = useState(false);
  const [memoId, setMemoId] = useState(0);
  let [actAccess, setActAccess] = useState({
    show: false,
    create: false,
    edit: false,
    delete: false,
  });
  const [isOpenDocumentDropDownButton, setIsOpenDocumentDropDownButton] =
    useState(false);

  useEffect(() => {
    initAccess();
  }, [advanceReportApplication]);

  const initAccess = async () => {
    if (!advanceReportApplication) {
      return;
    }

    let newActAccess = {
      show: false,
      create: false,
      edit: false,
      delete: false,
    };

    if (
      advanceReportApplication &&
      advanceReportApplication.properties &&
      advanceReportApplication.properties.includes("ActOfEntertaimentExpenses")
    ) {
      newActAccess.show = true;
    }

    if (
      user.id == advanceReportApplication.createdByEmployee.id ||
      user.id == advanceReportApplication.assigneeEmployee.id ||
      isCoordinatorOccupation() ||
      (await checkAssistance()) /* asist corden */
    ) {
      if (
        advanceReportApplication.status === STATUS_TYPE.DRAFT ||
        advanceReportApplication.status === STATUS_TYPE.APPROVAL_REQUIRES ||
        advanceReportApplication.status === STATUS_TYPE.REVOKED ||
        advanceReportApplication.status === STATUS_TYPE.NOT_APPROVED ||
        advanceReportApplication.status === STATUS_TYPE.NOTAPPROVED ||
        advanceReportApplication.status === STATUS_TYPE.ON_REVISION
      ) {
        newActAccess.create = true;
        newActAccess.edit = true;
        newActAccess.delete = true;
      }
    }

    if (
      advanceReportApplication.status == STATUS_TYPE.ON_APPROVAL &&
      isBookerOccupation() &&
      (await userInAgreementList())
    ) {
      newActAccess.create = true;
      newActAccess.edit = true;
      newActAccess.delete = true;
    }

    setActAccess(newActAccess);
  };

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  const isCoordinatorOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.COORDINATOR);
  };

  const userInAgreementList = async () => {
    let agreementList = await agreementService.getList();

    return (
      agreementList &&
      agreementList.approvalTiers
        .flatMap((item) => item.employees)
        .filter((item) => item.id == user.id).length > 0
    );
  };

  const checkAssistance = async () => {
    if (
      advanceReportApplication &&
      advanceReportApplication.assigneeEmployee &&
      advanceReportApplication.assigneeEmployee.id
    ) {
      let massRequest = user.occupations.map((occupation) =>
        getAssistancesById(occupation.occupationId),
      );
      let massResponse = await Promise.all(massRequest);
      let massList = massResponse
        .map((response) => {
          if (response.headers.success) {
            let assistancesList = get(response, "data.value.assistances", []);
            return assistancesList.map((item: any) => item.employeeId);
          }
          return [];
        })
        .flat();

      return massList.includes(advanceReportApplication.assigneeEmployee.id);
    }

    return false;
  };

  const handleActAdd = async () => {
    setActId(0);
    setOpenActModal(true);
    setIsOpenDocumentDropDownButton(false);
  };

  const handleMemoAdd = async () => {
    setMemoId(0);
    setOpenMemoModal(true);
    setIsOpenDocumentDropDownButton(false);
  };

  const handleActClose = () => {
    setOpenActModal(false);
  };
  const handleMemoClose = () => {
    setOpenMemoModal(false);
  };

  const handleComplete = () => {
    setOpenActModal(false);
    setOpenMemoModal(false);
    dispatch(updateDetailedAO(advanceReportApplication.id));
  };

  const toggleDeleteExpenseModal = () => {
    setOpenDeleteExpenseModal(!isOpenDeleteExpenseModal);
  };

  const handleDocumentDropDown = () => {
    setIsOpenDocumentDropDownButton(!isOpenDocumentDropDownButton);
  };

  const handleDelete = async () => {
    dispatch(loaderLock());
    dispatch(loaderUnlock());
  };

  return (
    <>
      <div
        className="request-expenses"
        style={{
          display:
            advanceReportApplication.properties.includes(
              ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES,
            ) ||
            advanceReportApplication.properties.includes(
              ADVANCE_APPLICATION_PROPETIES.SERVICE_NOTE,
            )
              ? undefined
              : "none",
        }}
      >
        <div className="request-expenses-title" style={{ height: "40px" }}>
          <span>
            {t("request_detail.application_expenses.representationExpenses")}
          </span>
          {advanceReportApplication.properties.includes(
            ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES,
          ) ||
          advanceReportApplication.properties.includes(
            ADVANCE_APPLICATION_PROPETIES.SERVICE_NOTE,
          ) ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <a
                className="btn btn_gray pointer"
                onClick={handleDocumentDropDown}
                style={{
                  width: "100px",
                  height: "40px",
                  textTransform: "none",
                  fontSize: "16px",
                }}
              >
                {t("request_detail.application_expenses.addDocument")}
              </a>
              {isOpenDocumentDropDownButton && (
                <DropdownButtonContainer>
                  {advanceReportApplication.properties.includes(
                    ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES,
                  ) && (
                    <DropdownButtonItem onClick={handleActAdd}>
                      <Svgicon id={"docBlank"} />
                      {t("request_detail.application_expenses.addAct")}
                    </DropdownButtonItem>
                  )}

                  {advanceReportApplication.properties.includes(
                    ADVANCE_APPLICATION_PROPETIES.SERVICE_NOTE,
                  ) && (
                    <DropdownButtonItem onClick={handleMemoAdd}>
                      <Svgicon id={"docFilled"} />
                      {t("request_detail.application_expenses.addMemo")}
                    </DropdownButtonItem>
                  )}
                </DropdownButtonContainer>
              )}
            </div>
          ) : null}
        </div>
        <div className="request-expenses-wrap">
          {props.documentArr.length
            ? props.documentArr.map((item) => {
                return (
                  <AODocument
                    item={item}
                    setActId={setActId}
                    setOpenActModal={setOpenActModal}
                    setMemoId={setMemoId}
                    setOpenMemoModal={setOpenMemoModal}
                  ></AODocument>
                );
              })
            : null}
        </div>
        <ADActModal
          isOpen={isOpenActModal}
          editId={actId}
          documentId={advanceReportApplication.id}
          onClose={handleActClose}
          onComplete={handleComplete}
        />
        <MemoModal
          isOpen={isOpenMemoModal}
          editId={memoId}
          documentId={advanceReportApplication.id}
          onClose={handleMemoClose}
          onComplete={handleComplete}
        />
        <DeleteExpenseModal
          id={0}
          isOpen={isOpenDeleteExpenseModal}
          onSubmit={handleDelete}
          onClose={toggleDeleteExpenseModal}
        />
      </div>
    </>
  );
};

export default AODocuments;
